import React, { useState, useEffect } from "react";
import { NavLink, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../../../actions/authActions";
import logo from "./images/side-navbar-logo.svg";
import ccHeart from "./images/cc-prem-heart.svg";

import * as Icon from "react-feather";
import environment from "../../../utils/environment";
import http from "../../../utils/http";
import microtip from "microtip/microtip.css";

import { getCurrentProfile } from "../../../actions/profileActions";

import "./Navbar.scss";
import CC__PremiumBtn from "./cc_premium_btn/CC_PremiumBtn";

const isProfileRoute = (match, location) =>
  ["/edit-profile", "/add-experience", "/add-availability"].includes(
    location.pathname
  );

const isInfoRoute = (match, location) =>
  ["/account", "/contact"].includes(location.pathname);

const isClientRoute = (match, location) =>
  ["/client-list", "/client-details"].includes(location.pathname);

const Navbar = (props) => {
  const logout = () => {
    props.logoutUser();
  };

  const [homeLink] = useState(false);

  // runs only on mount
  useEffect(() => {
    getCurrentProfile();
  }, [getCurrentProfile]);

  // /profile/me

  return (
    <div className="NavBar">
      <div className="navbar-logo-container">
        <NavLink to="/">
          <img src={logo} alt="Home" />
        </NavLink>
      </div>

      <div className="navbar-links-container">
        {/* {props.profile.profile ? (
          <button
            className="navbar-make-appt-btn"
            aria-label={"Coming Soon!"}
            data-microtip-position="top"
            role="tooltip"
          >
            <Icon.PlusCircle size={24} alt="Plus" /> Make Appointment
          </button>
        ) : (
          ""
        )} */}

        {!homeLink && (
          <NavLink
            exact
            to="/"
            className="navbar-link"
            activeClassName="selected"
          >
            {/* <img src={iconHome} alt="Home" /> */}

            <Icon.Home className="navbar-icon-default" size={24} alt="Home" />
            <span className="navbar-link-text">Dashboard</span>
          </NavLink>
        )}

        {/* { homeLink && (
          <NavLink exact to="/" className="navbar-link" activeClassName="selected">
            <img src={iconProfile} alt="Profile" /> 
            <Icon.Calendar className="navbar-icon-default" activeClassName="iconSelected" size={24} alt="Home"/>
            <span className="navbar-link-text">Appointments</span>
          </NavLink>
        )} 
        */}

        {props.profile.profile ? (
          <div>
            {/* <NavLink
              exact
              to="/client-list"
              isActive={isClientRoute}
              className="navbar-link"
              activeClassName="selected"
            >
              <Icon.Users
                className="navbar-icon-default"
                activeClassName="iconSelected"
                size={24}
                alt="Home"
              />
              <span className="navbar-link-text">Clients</span>
            </NavLink> */}
            <NavLink
              exact
              to="/edit-profile"
              isActive={isProfileRoute}
              className="navbar-link"
              activeClassName="selected"
            >
              <Icon.User
                className="navbar-icon-default"
                activeClassName="iconSelected"
                size={24}
                alt="Home"
              />
              <span className="navbar-link-text">Social Profile</span>
            </NavLink>
          </div>
        ) : (
          ""
        )}

        {/* <NavLink exact to="/messages" isActive={isProfileRoute} className="navbar-link" activeClassName="selected">
        
          <Icon.Calendar className="navbar-icon-default" activeClassName="iconSelected" size={24} alt="Home"/>
            <span className="navbar-link-text">Appointments</span>
        </NavLink>

        <NavLink exact to="/" isActive={isInfoRoute} className="navbar-link" activeClassName="selected">
      
          <Icon.MessageSquare className="navbar-icon-default" activeClassName="iconSelected" size={24} alt="Home"/>
            <span className="navbar-link-text">Messages</span>
        </NavLink> */}

        <NavLink
          exact
          to="/account"
          isActive={isInfoRoute}
          className="navbar-link"
          activeClassName="selected"
        >
          {/* <img src={iconProfile} alt="Profile" /> */}
          <Icon.Settings
            className="navbar-icon-default"
            activeClassName="iconSelected"
            size={24}
            alt="Home"
          />
          <span className="navbar-link-text">Account Settings</span>
        </NavLink>

        <NavLink
          exact
          to="/subscription"
          isActive={isInfoRoute}
          className="navbar-link"
        >
          {/* <img src={iconProfile} alt="Profile" /> */}
          <Icon.DollarSign
            className="navbar-icon-default"
            activeClassName="iconSelected"
            size={24}
            alt="Home"
          />
          <span className="navbar-link-text">Subscription</span>
        </NavLink>

        {/* <CC__PremiumBtn /> */}

        {/* <NavLink exact to="/account" isActive={isInfoRoute} className="navbar-link" activeClassName="selected">
          <img src={iconInfo} alt="Account Info" />
        </NavLink> */}

        <div className="navbar-logout-container">
          <span onClick={logout}>
            <Icon.LogOut
              className="navbar-logout-icon"
              size={28}
              alt="Logout"
            />
          </span>
        </div>
      </div>
    </div>
  );
};

Navbar.propTypes = {
  getCurrentProfile: PropTypes.func.isRequired,
  logoutUser: PropTypes.func.isRequired,
};

Navbar.defaultProps = {
  profile: {},
};

const mapStateToProps = (state) => ({
  profile: state.profile,
  auth: state.auth,
});

export default withRouter(
  connect(mapStateToProps, { getCurrentProfile, logoutUser })(Navbar)
);
