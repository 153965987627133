import React, { useState, useEffect, useCallback, useRef } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getCurrentProfile } from "../../../actions/profileActions";
import Spinner from "../../reusable/spinner/Spinner.jsx";
import http from "../../../utils/http";
import "./style/dashboard.scss";
import MicroModal from "micromodal";
import * as Icon from "react-feather";
import environment from "../../../utils/environment";
import EditAvailabilityModal from "./EditAvailabilityModal.jsx";
import { CopyToClipboard } from "react-copy-to-clipboard";
import microtip from "microtip/microtip.css";
import CreateClientModal from "../client-management/CreateClientModal.jsx";
import Subscription from "../profile_settings/subscription/Subscription";
import dateFormat from "dateformat";
// const days = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];

const clientReqList = [
  {
    name: "John Doe",
    email: "johnDoe21@gmail.com",
    phone: "647-312-3122",
    service: "Family Law",
    case_status: "waiting",
  },
  {
    name: "Bill Farington",
    email: "bill021@gmail.com",
    phone: "416-231-4241",
    service: "Real Estate",
    case_status: "pending",
  },
  {
    name: "Drake Abess",
    email: "bill021@gmail.com",
    phone: "416-231-4241",
    service: "Criminal Law",
    case_status: "resolved",
  },
  {
    name: "Muhammad Khan",
    email: "bill021@gmail.com",
    phone: "416-231-4241",
    service: "Employment",
    case_status: "pending",
  },
];

const Dashboard = (props) => {
  const { profile: profileState } = props;

  const { profile, loading } = profileState;
  const [profilePictureUrl, setProfilePictureUrl] = useState(null);
  const contactHoursRef = useRef(null);
  const [connectionsList, setConnections] = useState([]);
  const [copyEmail, setCopyEmail] = useState("Copy Email");
  const [word, setWord] = useState("Copy Link");
  // const [firstName, setFirstName] = useState();
  // const [lastName, setLastName] = useState();
  const [numOfPageViews, setNumOfPageViews] = useState(0);

  function highlightTodaysDate() {
    var d = new Date();
    var n = d.getDay();

    if (n === 0) {
      n = 6;
    } else {
      n--;
    }

    for (let i = 0; i < 3; i++) {
      contactHoursRef.current.children[n].children[i].style.color = "#D0021B";
      contactHoursRef.current.children[n].children[i].style.fontWeight = "bold";
    }
  }

  const getPageView = async () => {
    try {
      const response = await http.post(
        `${environment.resolveApi().rest}/profile/analytics/numOfPageViews`,
        { profileId: profile._id }
      );
      setNumOfPageViews(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const getUserInfo = async () => {
      try {
        highlightTodaysDate();
      } catch (error) {
        console.log(error);
      }
    };

    const getConnections = async () => {
      try {
        const response = await http.get(
          `${environment.resolveApi().rest}/connections/list`
        );
        setConnections(response.data);
      } catch (error) {
        console.log(error);
      }
    };

    if (profile) {
      const { pictureURL } = profile;
      setProfilePictureUrl(pictureURL);
      getUserInfo();
      getConnections();
      getPageView();
    }
  }, [profile, contactHoursRef]);

  const onEditAvailability = useCallback(
    () => MicroModal.show("edit-availability-modal"),
    []
  );
  const onNewClientModal = useCallback(
    () => MicroModal.show("create-client-modal"),
    []
  );

  // early returns
  if (loading) return <Spinner />;
  if (!profile) {
    return (
      <div className="no-profile-dashboard-container">
        <img
          className="no-profile-dashboard-img"
          src={require("./images/no-profile.svg")}
          alt="company logo"
        />
        <p className="no-profile-dashboard-title">
          Welcome to CounselConnect for Sole Practitioners!
        </p>
        <p className="no-profile-dashboard-subtitle">
          It’s pretty empty in here, get started by adding some info.
        </p>
        <Link to="/create-profile" className="no-profile-dashboard-btn">
          Create Profile
        </Link>
      </div>
    );
  }

  const { handle, status, field, city } = profile;
  // const welcomeMsg = `Welcome, ${firstName} ${lastName}`;
  const socialProfileLink = `profile/${handle}`;

  return (
    <div className="Dashboard">
      <h1>Dashboard</h1>

      <div className="dashboard-row-1">
        <div className="dashboard-row-1-card-2">
          <img
            className="dashboard-row-1-card-2-image"
            src={require("./images/walkingMan.svg")}
            alt=""
          ></img>
          <div className="dashboard-row-1-card-2-title-container">
            <span className="dashboard-row-1-card-2-title">
              👋 Hello {profile.first_name}!
            </span>
            <span className="dashboard-row-1-card-2-message">
              Here are your important tasks, updates and alerts.
            </span>
          </div>
        </div>
      </div>

      <div className="dashboard-row-2">
        <div className="dashboard-row-2-card-1">
          <div className="dashboard-row-2-card-1-header-container">
            <span>Availability</span>
            <button
              className="dashboard-row-2-card-1-btn"
              onClick={onEditAvailability}
              type="button"
            >
              Edit
            </button>
          </div>

          <div
            className="dashboard-row-2-card-1-container"
            ref={contactHoursRef}
          >
            <div className="dashboard-row-2-card-1-daytime-container">
              <span className="dashboard-row-2-card-1-day">Mon.</span>
              <span className="dashboard-row-2-card-1-time">
                {profile.availability.monday.from} -{" "}
                {profile.availability.monday.to}
              </span>
            </div>
            <div className="dashboard-row-2-card-1-daytime-container">
              <span className="dashboard-row-2-card-1-day">Tue.</span>
              <span className="dashboard-row-2-card-1-time">
                {profile.availability.tuesday.from} -{" "}
                {profile.availability.tuesday.to}
              </span>
            </div>
            <div className="dashboard-row-2-card-1-daytime-container">
              <span className="dashboard-row-2-card-1-day">Wed.</span>
              <span className="dashboard-row-2-card-1-time">
                {profile.availability.wednesday.from} -{" "}
                {profile.availability.wednesday.to}
              </span>
            </div>
            <div className="dashboard-row-2-card-1-daytime-container">
              <span className="dashboard-row-2-card-1-day">Thurs.</span>
              <span className="dashboard-row-2-card-1-time">
                {profile.availability.thursday.from} -{" "}
                {profile.availability.thursday.to}
              </span>
            </div>
            <div className="dashboard-row-2-card-1-daytime-container">
              <span className="dashboard-row-2-card-1-day">Fri.</span>
              <span className="dashboard-row-2-card-1-time">
                {profile.availability.friday.from} -{" "}
                {profile.availability.friday.to}
              </span>
            </div>
            <div className="dashboard-row-2-card-1-daytime-container">
              <span className="dashboard-row-2-card-1-day">Sat.</span>
              <span className="dashboard-row-2-card-1-time">
                {profile.availability.saturday.from} -{" "}
                {profile.availability.saturday.to}
              </span>
            </div>
            <div className="dashboard-row-2-card-1-daytime-container">
              <span className="dashboard-row-2-card-1-day">Sun.</span>
              <span className="dashboard-row-2-card-1-time">
                {profile.availability.sunday.from} -{" "}
                {profile.availability.sunday.to}
              </span>
            </div>
          </div>
        </div>

        <div className="dashboard-row-2-card-2">
          <span>Client Connections</span>
          <div className="dashboard-row-2-card-2-container">
            <table className="dashboard-table">
              {connectionsList && connectionsList.length > 0 ? (
                <tbody>
                  {connectionsList.map((client, index) => {
                    return (
                      <tr key={index} className="dashboard-row-2-card-2-row">
                        {/* <img className="dashboard-row-2-card-2-image"  src={require("./images/diamondPalmor.png")} alt=""></img> */}

                        <td>
                          <div className="td-1-dashboard">{`${client.first_name} `}</div>
                        </td>

                        <td>
                          <div
                            className="td-2-dashboard"
                            style={{ width: 300, overflowX: "scroll" }}
                          >{`${client.message} `}</div>
                        </td>
                        <td>
                          <div className="td-2-dashboard">
                            {dateFormat(client.createdAt, "mmmm dd, hh:mm TT")}
                          </div>
                        </td>

                        <div style={{ display: "flex", alignItems: "center" }}>
                          <td>
                            <div
                              className="td-2 td-email"
                              onClick={() => {
                                // setCopyEmail("Copied 🎉");
                              }}
                            >
                              <span>View Details</span>
                              <Icon.ChevronRight
                                className="client-phone-icon"
                                size={24}
                                color={"#D0011C"}
                              />
                            </div>
                          </td>

                          {/* <td>
                            <CopyToClipboard text={client.email}>
                              <div
                                className="td-2 td-email"
                                aria-label={copyEmail}
                                data-microtip-position="left"
                                role="tooltip"
                                onClick={() => {
                                  setCopyEmail("Copied 🎉");
                                }}
                              >
                                <Icon.Mail
                                  className="client-phone-icon"
                                  size={24}
                                  color={"#58B400"}
                                />
                              </div>
                            </CopyToClipboard>
                          </td> */}

                          {/* <td>
                            <a
                              className="td-3-link"
                              href={`tel:${client.phoneNumber}`}
                            >
                              <div
                                className="td-3"
                                aria-label={"Call 📞"}
                                data-microtip-position="left"
                                role="tooltip"
                              >
                                <Icon.Phone
                                  className="client-phone-icon"
                                  size={24}
                                  color={"#FFC700"}
                                />
                              </div>
                            </a>
                          </td> */}

                          {/* <td>
                            <div
                              className="td-5"
                              aria-label={"Add Client 🥳"}
                              data-microtip-position="left"
                              role="tooltip"
                              onClick={onNewClientModal}
                            >
                              <Icon.UserPlus
                                className="client-arrow-right-icon"
                                size={24}
                                color={"rgb(235, 62, 79)"}
                              />
                            </div>
                          </td> */}
                        </div>
                      </tr>
                    );
                  })}
                </tbody>
              ) : (
                <div
                  style={{
                    height: "100%",
                    display: "flex",
                    flexFlow: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <p style={{ fontWeight: "400", color: "#CEC6C6" }}>
                    No client requests at the moment..
                  </p>
                  <p style={{ fontWeight: "400", color: "#CEC6C6" }}>
                    Share your social profile link to get more clients faster!
                  </p>
                  <Icon.Frown
                    className="client-phone-icon"
                    size={48}
                    color={"#CEC6C6"}
                  />
                </div>
              )}
            </table>
          </div>
        </div>
      </div>

      <div className="dashboard-row-3">
        <div className="dashboard-row-3-card-1-box">
          <span>Social Profile</span>
          <div className="dashboard-row-3-card-1-wrap">
            <div className="dashboard-row-3-card-1-inner-wrap">
              <div>
                <img
                  className="dashboard-profile-image"
                  src={profilePictureUrl}
                  alt=""
                />
              </div>

              <Link
                to={socialProfileLink}
                className="dashboard-row-3-card-1-btn-wrap"
                target="_blank"
              >
                <span className="dashboard-row-3-card-1-btn-text">View</span>
                <Icon.ExternalLink
                  className="dashboard-social-icon"
                  size={23}
                  color={"#D0021B"}
                />
              </Link>

              <CopyToClipboard
                text={`https://app.counselconnect.ca/${socialProfileLink}`}
              >
                <div
                  className="dashboard-row-3-card-1-btn-wrap-2"
                  aria-label={word}
                  data-microtip-position="top"
                  role="tooltip"
                  onClick={() => {
                    setWord("Copied 🎉");
                  }}
                >
                  <span className="dashboard-row-3-card-1-btn-text">Share</span>
                  <Icon.Copy
                    className="dashboard-social-icon"
                    size={23}
                    color={"#D0021B"}
                  />
                </div>
              </CopyToClipboard>
            </div>
          </div>
        </div>

        <div className="dashboard-row-3-card-2-box">
          <span>Client Connections</span>

          <div className="dashboard-row-3-card-2-wrap">
            <div className="dashboard-row-3-card-2-inner-wrap">
              {profile.client_connections &&
              profile.client_connections.length > 0 ? (
                <div>
                  <span className="dashboard-row-3-card-2-text">
                    {profile.client_connections.length}
                  </span>
                </div>
              ) : (
                <div>
                  <span className="dashboard-row-3-card-2-text">0</span>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="dashboard-row-3-card-3-box">
          <span>Profile Views</span>

          {numOfPageViews > 0 ? (
            <div className="dashboard-row-3-card-3-wrap">
              <div className="dashboard-row-3-card-3-inner-wrap">
                <span className="dashboard-row-3-card-3-text">
                  {numOfPageViews}
                </span>
              </div>
            </div>
          ) : (
            <div className="dashboard-row-3-card-3-wrap">
              <div className="dashboard-row-3-card-3-inner-wrap">
                <span className="dashboard-row-3-card-3-text">0</span>
              </div>
            </div>
          )}
        </div>

        {/* <span>Copyright 2019</span> */}
      </div>

      <EditAvailabilityModal
        modalId="edit-availability-modal"
        modalTitle="Edit Availablity"
        {...props}
      />

      <CreateClientModal
        modalId="create-client-modal"
        modalTitle="Create Client"
        history={props.history}
        {...props}
      />
    </div>
  );
};

Dashboard.propTypes = {
  profile: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  profile: state.profile,
});

export default connect(mapStateToProps, { getCurrentProfile })(Dashboard);
