import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import SoleLawProfile from './SoleLawProfile';
import NavBar from '../../reusable/social_navbar/Navbar.jsx';
import Spinner from '../../reusable/spinner/Spinner.jsx';
import profileContainer from './Style/profileContainer.scss';
import { getProfileByHandle } from '../../../actions/profileActions';
import * as Icon from 'react-feather';


class SocialProfile extends Component {
  


  componentDidMount() {
    if (this.props.match.params.handle) {
      // this.props.getProfileByHandle(this.props.match.params.handle);
      this.props.getProfileByHandle(this.props.match.params.handle);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.profile.profile === null && this.props.profile.loading) {
      this.props.history.push('/not-found');
    }
  }

  render() {
    const { profile, loading } = this.props.profile;
    let profileContent;

    if (profile === null || loading) {
      profileContent = <Spinner />;
    } else {
      profileContent = (
        <div>
          
          <SoleLawProfile profile={profile} />

        
        </div>
      );
    }

    return (
      <div >

        {/* <NavBar /> */}

        <div className="lawyer-search-social-profile-mobile-navbar"><a href="https://counselconnect.ca/"><img className="lawyer-search-social-profile-mobile-navbar-img" src={require('../law_search/images/cc-single-logo.svg')} alt="counsel-connect-log" /></a></div>

      <div className="search-header mobile-hide table-view">
        <a href="https://counselconnect.ca/"><img className="social-profile-nav-cc-logo" src={require('../law_search/images/cc-search-logo.svg')} alt="counsel-connect-log" /></a>
        {/* <a href="https://app.counselconnect.ca/search_firm" className="social-profile-return-to-matches-link"><Icon.List className="list-icon" size={22} strokeWidth={1.5} color={"#806B6C"}  /><span>RETURN TO MATCHES</span></a> */}
      </div>

        <div className="social-profile-container">
          <div className="">
            <div className="">{profileContent}</div>
          </div>
        </div>
      </div>
    );
  }
}

SocialProfile.propTypes = {
  getProfileByHandle: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  profile: state.profile,
});

export default connect(mapStateToProps, { getProfileByHandle })(SocialProfile);
