import React, { useState, useEffect, useCallback } from "react";
import "./location.scss";
import "./googleServices.scss";
import { withAlert } from "react-alert";
import http from "../../../utils/http";
import environment from "../../../utils/environment";
import PlacesAutocomplete, {
  geocodeByAddress,
  geocodeByPlaceId,
  getLatLng,
  getCity,
} from "react-places-autocomplete";

import { getDistance } from "geolib";

import { motion } from "framer-motion";
import dateFormat from "dateformat";

let arrOfCities = [
  // { city: "Mississauga", lat: "43.595310", lng: "-79.640579" },
  { city: "Toronto", lat: "43.651070", lng: "-79.347015" },
  // { city: "Ottawa", lat: "45.424721", lng: "-75.695000" },
  // { city: "Hamilton", lat: "43.255203", lng: "-79.843826" },
  // { city: "Burlington", lat: "43.328674", lng: "-79.817734" },
  // { city: "Brampton", lat: "43.683334", lng: "-79.766670" },
  // { city: "Kitchener", lat: "43.452969", lng: "-80.495064" },
  // { city: "Markham", lat: "43.856098", lng: "-79.337021" },
  // { city: "Scarborough", lat: "43.777702", lng: "-79.233238" },
  // { city: "Sudbury", lat: "46.490002", lng: "-81.010002" },
  // { city: "Windsor", lat: "42.317432", lng: "-83.026772" },
  // { city: "London", lat: "42.983612", lng: "-81.249725" },
  // { city: "Kingston", lat: "44.233334", lng: "-76.500000" },
];

const Location = (props) => {
  if (props.location.state) {
    if (props.location.state.resetComponent) {
      console.log("resetComponent - TRUE");

      window.location.reload(true);
      props.history.push({
        pathname: "/recommendation/reason",
        state: { resetComponent: false },
      });
    }
    if (!props.location.state.resetComponent) {
      console.log("resetComponent - FALSE");
    }
  }

  const [loading, setLoading] = useState(false);

  const [address, setAddress] = useState("");
  // const [city, setCity] = useState("");
  const [coordinates, setCoordinates] = useState({ lat: null, lng: null });

  let city = "";

  const onSubmit = (closestDistance) => {
    let clientLatLng = coordinates.lat + "," + coordinates.lng;
    const local_search = localStorage.getItem("search_save");
    const local_search_parsed = JSON.parse(local_search);
    // save to localstorage
    const dataToSave = {
      date: dateFormat(new Date(), "mmmm dd"),
      pathname: "recommendation/location",
      service_request: local_search_parsed.service_request,
      message: local_search_parsed.message,
      aiResponse: local_search_parsed.aiResponse,
      criteria: local_search_parsed.criteria,
      urgency: local_search_parsed.urgency,
      availability: local_search_parsed.availability,
      status: "pending",
      city: closestDistance,

      income: local_search_parsed.income,
    };

    localStorage.setItem("search_save", JSON.stringify(dataToSave));

    props.history.push({
      pathname: `/search/${clientLatLng}/${closestDistance.city}/Lawyer/${local_search_parsed.service_request}/Both/Law%20Firm`,
    });
  };

  const onSubmitFields = (closestDistance) => {
    let clientLatLng = coordinates.lat + "," + coordinates.lng;

    // console.log("closestDistance", closestDistance);

    // save to localstorage
    const dataToSave = {
      pathname: `/search/${clientLatLng}/${closestDistance.city}`,
      date: dateFormat(new Date(), "mmmm dd"),
      city: closestDistance.city,
      status: "pending",
    };

    //   {
    //     "city": "Toronto",
    //     "lat": "43.651070",
    //     "lng": "-79.347015",
    //     "distance": 2923
    // }

    localStorage.setItem("search_save", JSON.stringify(dataToSave));

    props.history.push({
      pathname: `/search/${clientLatLng}/${closestDistance.city}/Lawyer`,
    });
  };

  const btnClick = async () => {
    const closestCity = await validateCity();

    if (!coordinates.lat && !coordinates.lng) {
      props.alert.error(
        "You must select a valid address from the search suggestions!"
      );
      return;
    }

    // await onSubmitFields(closestCity);
    await onSubmit(closestCity);
  };

  function compare(a, b) {
    if (a.distance < b.distance) {
      return -1;
    }
    if (a.distance > b.distance) {
      return 1;
    }
    return 0;
  }

  const validateCity = async () => {
    let result = false;

    if (!address || address.length === 0) {
      props.alert.error("Please enter in your location address!");
      return result;
    }

    try {
      // loop through the list of available CounselConnect cites
      for (let i = 0; i < arrOfCities.length; i++) {
        // calculate the distance between the user and the ith city
        const distanceInMeters = await getDistance(
          {
            latitude: coordinates.lat,
            longitude: coordinates.lng,
          },
          {
            latitude: arrOfCities[i].lat,
            longitude: arrOfCities[i].lng,
          }
        );

        // add a new field to city that holds the distance from usr
        arrOfCities[i].distance = distanceInMeters;
      }

      // console.log('arrOfCities after: ', arrOfCities)

      const sortedDistances = arrOfCities.sort(compare);
      const closestDistance = sortedDistances[0];

      // return the closet city users input
      // console.log('closestDistance', closestDistance)

      // result = true;
      return closestDistance;
    } catch (error) {
      if (error.distance) {
        props.alert.error(error.distance);
      }

      console.log(error);
    }
  };

  const handleSelect = async (value) => {
    console.log("value", value);
    const results = await geocodeByAddress(value);
    const latLng = await getLatLng(results[0]);

    setAddress(value);
    setCoordinates(latLng);
    // await btnClick();
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      style={{ backgroundColor: "#FFF" }}
    >
      <div className="searchLocation">
        <div className="ccSearch__header">
          <a href="https://counselconnect.ca/">
            <img
              className="lawyer-search-navbar-logo"
              src={require("./images/cc-search-logo--blue.svg")}
              alt="counsel-connect-log"
            />
          </a>
        </div>
        <div className="Location search-location-pg-conatainer">
          {/* <img
          className="location-search-header-icon"
          src={require("./images/location.svg")}
          alt=""
        /> */}
          <h4 className="explainCase__content__title">
            Where are you located?
          </h4>

          {/* <span className="social-profile-txt-light lawyer-search-title">
          Enter in your location and select a valid address.
        </span> */}

          <div className="law-field-options">
            <PlacesAutocomplete
              value={address}
              onChange={setAddress}
              onSelect={handleSelect}
            >
              {({
                getInputProps,
                suggestions,
                getSuggestionItemProps,
                loading,
              }) => (
                <div>
                  <div className="google-places-input-wrapper">
                    <input
                      {...getInputProps({
                        placeholder: "Address or Postal Code",
                        className: "location-search-input",
                      })}
                    />
                    <button
                      className="google-places-submit-btn"
                      onClick={btnClick}
                    >
                      Continue
                    </button>
                  </div>

                  <div className="google-places-results-wrapper">
                    {loading ? <div>...Loading</div> : null}

                    {suggestions.map((suggestion) => {
                      const style = suggestion.active
                        ? { backgroundColor: "#FBF7F7", cursor: "pointer" }
                        : { backgroundColor: "#ffffff", cursor: "pointer" };

                      return (
                        <div
                          className="google-places-results"
                          {...getSuggestionItemProps(suggestion, { style })}
                        >
                          <img
                            className=" profile-card-rating-start-img"
                            style={{ marginRight: "10px" }}
                            src={require("./images/pin.svg")}
                          />
                          {suggestion.description}
                        </div>
                      );
                    })}

                    {suggestions.length > 0 ? (
                      <div className="powered-by-google-bar">
                        <img
                          className="powered-by-google-img"
                          src={require("./images/poweredbygoogle.svg")}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              )}
            </PlacesAutocomplete>

            {/* <button
            type="submit"
            className="status-search-lawyer-button"
            onClick={() => {
              handleSelect("Mississauga, ON, Canada");
            }}
            value="Mississauga"
          >
            Mississauga
          </button> */}
            {/* <button
            type="submit"
            className="status-search-lawyer-button"
            onClick={() => {
              onSubmitFields();
            }}
            value="Toronto"
          >
            Toronto
          </button> */}
          </div>
          {/* <div className="location__servingBanner">
          <span className="location__servingBanner__txt">
            Exclusively serving Toronto Lawyers at this point in time
          </span>{" "}
        </div> */}
          {/* 
        <div className="location__servingBanner ">
          Exclusively serving Toronto Lawyers at this point in time
        </div> */}

          {/* <span className="location-search-subtitle-msg">Currently serving Mississauga and Toronto!</span> */}
          {/* <div className="location-page-num  ">
          Exclusively serving Toronto Lawyers at this point in time
        </div> */}

          {/* <div className="location-page-num">1 of 2</div> */}
        </div>
      </div>
    </motion.div>
  );
};

export default withAlert()(Location);
