import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { Map, GoogleApiWrapper, Marker } from "google-maps-react";
import { getCurrentProfile } from "../../../actions/profileActions";
import http from "../../../utils/http";
import TextAreaField from "../../reusable/text_area_field/TextAreaField";
import SelectList from "../../reusable/select_list/SelectList";
import useForm from "../../../hooks/useForm";
// import iconProfile from '../layout/navbar/images/profile-icon.svg';
import "./Style/socialProfile.scss";
import { withAlert } from "react-alert";
import environment from "../../../utils/environment";
import RatingStar from "./images/star.svg";
import * as Icon from "react-feather";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Tooltip from "react-tooltip-lite";
import "./Style/review-container.css";
import Moment from "react-moment";
import StarRatings from "react-star-ratings";
import ReviewsSection from "./ReviewsSection";
import FirmsReviewsSection from "./FirmsReviewsSection";
import "./Style/FirmEmpLawProfile.scss";

const mapStyles = {
  width: "100%",
  height: "350px",
  display: "block !important",
  position: "relative !important",
};

const paralegalCategories = [
  { label: "* Select Service", value: 0 },
  { label: "Traffic Tickets", value: "Traffic Tickets" },
  { label: "Minor Offences", value: "Minor Offences" },
  { label: "Landlord or Tenant", value: "Landlord or Tenant" },
  { label: "Employment Law", value: "Employment Law" },
  { label: "Small Claims", value: "Small Claims" },
  { label: "Immigration Law", value: "Immigration Law" },
];

const lawyerCategories = [
  { label: "* Select Service", value: 0 },
  { label: "Criminal Law", value: "Criminal Law" },
  { label: "Commercial Law", value: "Commercial Law" },
  { label: "Civil Law", value: "Civil Law" },
  { label: "Employment Law", value: "Employment Law" },
  { label: "Family Law", value: "Family Law" },
  { label: "Immigration Law", value: "Immigration Law" },
  { label: "Real Estate", value: "Real Estate" },
  { label: "Personal Injury", value: "Personal Injury Law" },
  { label: "Wills, Estates, Trusts", value: "Wills, Estates, Trusts" },
  { label: "Environment Law", value: "Environment Law" },
  { label: "Insurance Law", value: "Insurance Law" },
  { label: "Property Law", value: "Property Law" },
  { label: "Administrative Law", value: "Administrative Law" },
  { label: "Entertainment Law", value: "Entertainment Law" },
];

const getAvgRating = (listOfReviews) => {
  let average = 0;

  // loop through the list of reviews and add up the total rating values
  if (listOfReviews.length > 0) {
    for (let i = 0; i < listOfReviews.length; i++) {
      average += parseInt(listOfReviews[i].rating);
    }
    average = average / listOfReviews.length;
  }

  return average.toFixed(1);
};

const FirmEmpLawProfile = (props) => {
  const { handle } = props.match.params;
  const { profile: profileState, google } = props;
  // const { profile } = profileState;
  const contactHoursRef = useRef(null);

  const [profile, setProfile] = useState({});
  const [employeeReviews, setEmployeeReviews] = useState({});
  const [lawFirm, setLawFirm] = useState({});

  const [profilePictureUrl, setProfilePictureUrl] = useState(null);
  const [bookingTimes, viewBookingTimes] = useState(true);
  const [contactForm, viewContactForm] = useState(false);
  const [callForm, viewCallForm] = useState(false);
  const [successCall, showSuccessCall] = useState(false);
  const [successMessage, showSuccessMessage] = useState(false);
  const [copyProfileLink, setCopyProfile] = useState("Copy Profile Link");
  const [loading, setLoading] = useState(false);
  const [shareProfileLink, setShareProfile] = useState("Copy Profile Link");

  const [rating, setRating] = useState(0);

  const [showFirmRev, setShowFirmRev] = useState(false);
  const [showEmpRev, setShowEmpRev] = useState(true);

  const onSubmit = async (inputs) => {
    try {
      const local_search = localStorage.getItem("search_save");
      const searchData = JSON.parse(local_search);

      const payload = {
        handle,
        first_name: inputs.firstName,
        email: inputs.email,
        phone_number: inputs.phoneNumber,
        message: inputs.message,
        service_request: searchData.service_request,
      };

      if (!payload.message) {
        props.alert.error("Missing Input!");
        return console.error("missing message");
      }

      props.alert.info("Loading...");
      await http.post(
        `${environment.resolveApi().rest}/mailing/employee/connection`,
        payload
      );
      props.alert.success(`${profile.law_title} Notified!`);
      showSuccessMessage(true);
    } catch (error) {
      if (error.email) {
        props.alert.error(error.email);
      }
      if (error.phone_number) {
        props.alert.error(error.phone_number);
      }
      if (error.first_name) {
        props.alert.error(error.first_name);
      }
    }
  };

  const onCallSubmit = async (inputs) => {
    try {
      const local_search = localStorage.getItem("search_save");
      const searchData = JSON.parse(local_search);

      const payload = {
        handle,
        first_name: inputs.firstName,
        email: inputs.email,
        phone_number: inputs.phoneNumber,
        service_request: searchData.service_request,
      };

      payload.message = "";

      props.alert.info("Loading...");
      await http.post(
        `${environment.resolveApi().rest}/mailing/employee/connection`,
        payload
      );
      props.alert.success(`${profile.law_title} Notified!`);
      showSuccessCall(true);
    } catch (error) {
      if (error.email) {
        props.alert.error(error.email);
      }
      if (error.phone_number) {
        props.alert.error(error.phone_number);
      }
      if (error.first_name) {
        props.alert.error(error.first_name);
      }
    }
  };

  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();

  function highlightTodaysDate() {
    var d = new Date();
    var n = d.getDay();

    if (n === 0) {
      n = 6;
    } else {
      n--;
    }

    for (let i = 0; i < 3; i++) {
      contactHoursRef.current.children[n].children[i].style.color = "#157FFB";
      contactHoursRef.current.children[n].children[i].style.fontWeight = "bold";
    }
  }

  const { inputs, handleInputChange, handleSubmit } = useForm(onSubmit);

  // const { inputs, handleInputChange, handleCallSubmit } = useForm(onCallSubmit);

  useEffect(() => {
    const getEmployeeProfile = async () => {
      try {
        // TODO: add handle here
        const response = await http.get(
          `${environment.resolveApi().rest}/profile/employee/${handle}`
        );
        console.log(response.data.profile);
        setProfile(response.data.profile);

        // Get law firms info
        const getLawFirmInfo = async (lawFirmId) => {
          try {
            const response = await http.get(
              `${environment.resolveApi().rest}/profile/law_firm/${lawFirmId}`
            );
            // console.log(response.data.law_firm);
            setLawFirm(response.data.law_firm);
            // console.log(response.data.law_firm);

            highlightTodaysDate();
          } catch (error) {
            console.log(error);
          }
        };

        getLawFirmInfo(response.data.profile.law_firm_info);
      } catch (error) {
        console.log(error);
      }
    };
    if (profile) {
      getEmployeeProfile();
      setEmployeeReviews(profile.reviews);
    }
  }, [contactHoursRef]);

  // early returns
  // if (loading) return <div>loading</div>;
  if (!profile) return <div>no profile</div>;

  const description = `${profile.law_title}`;

  const changeRating = (newRating, name) => {
    setRating(newRating);
  };

  return (
    <div>
      <div className="lawyer-search-social-profile-mobile-navbar">
        <a href="https://counselconnect.ca/">
          <img
            className="lawyer-search-social-profile-mobile-navbar-img"
            src={require("../law_search/images/cc-single-logo.svg")}
            alt="counsel-connect-log"
          />
        </a>
      </div>

      <div className="search-header mobile-hide table-view">
        <a href="https://counselconnect.ca/">
          <img
            className="social-profile-nav-cc-logo"
            src={require("../law_search/images/cc-search-logo--blue.svg")}
            alt="counsel-connect-log"
          />
        </a>
        {/* <a href="https://app.counselconnect.ca/recommendation/reason" className="social-profile-return-to-matches-link"><Icon.Search className="list-icon" size={22} strokeWidth={1.5} color={"#806B6C"}  /><span>RETURN TO SEARCH</span></a> */}
      </div>

      <div className="social-profile-container">
        <div className="">
          <div className="SocialProfile">
            {lawFirm.lat && lawFirm.lat ? (
              <div>
                <Map
                  google={google}
                  zoom={14}
                  containerStyle={{ position: "flex" }}
                  initialCenter={{ lat: lawFirm.lat, lng: lawFirm.lng }}
                  disableDoubleClickZoom={true}
                  disableDefaultUI={true}
                  maxZoom={14}
                  minZoom={14}
                  style={{ height: "130px", marginTop: "84px" }}
                >
                  <Marker position={{ lat: lawFirm.lat, lng: lawFirm.lng }} />
                </Map>
              </div>
            ) : (
              ""
            )}

            <div>
              <div className="social-profile-header-container">
                <div className="social-profile-profile-card-n-review-wrapper">
                  <div className="social-profile-card-wrapper">
                    <img
                      className="social-profile-view-image employee-profile-view-image"
                      src={profile.pictureURL}
                      alt=""
                    />
                    <div className="social-employee-profile-header-text-container">
                      <div className="emp-card-cc-trusted-wrap">
                        <img
                          style={{
                            width: "25px",
                            marginRight: "10px",
                            marginBottom: "10px",
                          }}
                          src={require("../law_public_profile/images/trustHeart.svg")}
                          alt=""
                        />
                        <strong className="emp-card-cc-trusted-txt social-profile-txt-red ">
                          COUNSELCONNECT TRUSTED
                        </strong>
                      </div>

                      <h1 className="social-profile-name">{`${profile.first_name} ${profile.last_name}`}</h1>
                      <h4 className="social-profile-txt-light">
                        {description}
                      </h4>
                      <p className="social-profile-txt-light">
                        {profile.lawyer_type ? profile.lawyer_type : ""}{" "}
                      </p>

                      <div className="social-profile-website-links-container">
                        {/* {lawFirm.website ? (
                          <a
                            className="social-profile-website-link"
                            href={lawFirm.website}
                            target="_blank"
                          >
                            <img
                              width={"20px"}
                              src={require("./images/globe.svg")}
                              alt=""
                            />
                            Website
                          </a>
                        ) : (
                          ""
                        )} */}
                        {/* {lawFirm.linkedin ? (
                          <a
                            className="social-profile-website-link"
                            href={lawFirm.linkedin}
                            target="_blank"
                          >
                            <img
                              src={require("./images/linkedin.svg")}
                              alt=""
                            />
                            Linkedin
                          </a>
                        ) : (
                          ""
                        )} */}

                        <CopyToClipboard
                          text={`https://app.counselconnect.ca/profile/employee/${profile.handle}`}
                        >
                          <a
                            className="social-profile-website-link mobile-hide"
                            target="_blank"
                            aria-label={shareProfileLink}
                            data-microtip-position="left"
                            role="tooltip"
                            onClick={() => {
                              setShareProfile("Copied 🎉");
                            }}
                          >
                            <Icon.Share2
                              className="phone-icon"
                              size={18}
                              strokeWidth={2}
                              color={"#FC6970"}
                              style={{ marginRight: "10px" }}
                            />
                            Share
                          </a>
                        </CopyToClipboard>
                      </div>

                      <CopyToClipboard
                        text={`https://app.counselconnect.ca/profile/employee/${profile.handle}`}
                      >
                        <a
                          className="social-profile-website-link mobile-show"
                          target="_blank"
                          aria-label={shareProfileLink}
                          data-microtip-position="left"
                          role="tooltip"
                          onClick={() => {
                            setShareProfile("Copied 🎉");
                          }}
                        >
                          <Icon.Share2
                            className="phone-icon"
                            size={18}
                            strokeWidth={2}
                            color={"#FC6970"}
                            style={{ marginRight: "10px" }}
                          />
                          Share
                        </a>
                      </CopyToClipboard>

                      <img
                        style={{
                          maxWidth: "180px",
                          maxHeight: "160px",
                          marginTop: "2.5em",
                        }}
                        src={lawFirm.companyLogo}
                        alt="logo"
                      />
                    </div>

                    <div></div>
                  </div>

                  {profile.best_review && profile.reviews_avg_rating ? (
                    <div className="">
                      <div className="">
                        <hr
                          style={{
                            width: "100%",
                            border: "1px solid #F3F1F3",
                            marginBottom: "30px",
                          }}
                        />

                        <div className="social-profile-best-rating-number-wrapper">
                          <div className="social-profile-best-rating-star-wrapper">
                            <h1
                              style={{ display: "flex" }}
                              className="social-profile-txt-dark social-profile-txt-bold"
                            >
                              {profile.reviews_avg_rating
                                ? profile.reviews_avg_rating
                                : getAvgRating(profile.reviews)}
                              <h5
                                className="social-profile-txt-light"
                                style={{ marginTop: "15px" }}
                              >
                                /5
                              </h5>
                            </h1>
                            <div className="rating-container best-review-stars-wrapper">
                              <StarRatings
                                className="rating-container"
                                rating={parseInt(profile.reviews_avg_rating)}
                                starRatedColor="#3AC18D"
                                // changeRating={()=>changeRating(2)}
                                numberOfStars={5}
                                name="rating"
                                starDimension="26px"
                                starSpacing="2px"
                              />
                            </div>
                            <p
                              style={{ marginTop: "10px" }}
                              className="social-profile-txt-light"
                            >{`${profile.reviews.length} Reviews`}</p>
                          </div>

                          <div className="social-profile-most-helpful-review-text-container">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="28"
                              height="23"
                              viewBox="0 0 26 23"
                            >
                              <path
                                fill="#D0CDD0"
                                fill-rule="evenodd"
                                d="M0 15.025c0-2.4.363-4.57 1.088-6.508.726-1.94 1.667-3.488 2.825-4.646 1.158-1.157 2.372-2.056 3.64-2.698C8.824.53 10.1.14 11.384 0v4.06c-1.813.39-3.32 1.41-4.52 3.055-1.2 1.646-1.827 3.39-1.883 5.232.42-.28.99-.42 1.716-.42 1.48 0 2.707.475 3.683 1.424.976.95 1.464 2.22 1.464 3.81 0 1.423-.537 2.636-1.61 3.64-1.075 1.005-2.408 1.507-3.998 1.507-1.786 0-3.27-.627-4.457-1.883C.592 19.17 0 17.37 0 15.024zm14 0c0-2.4.363-4.57 1.088-6.508.726-1.94 1.674-3.488 2.846-4.646 1.172-1.157 2.393-2.056 3.662-2.698C22.866.53 24.142.14 25.426 0v4.06c-1.814.39-3.328 1.41-4.54 3.055-1.215 1.646-1.85 3.39-1.906 5.232.42-.28.99-.42 1.716-.42 1.48 0 2.707.475 3.683 1.424.976.95 1.464 2.22 1.464 3.81 0 1.423-.53 2.636-1.59 3.64-1.06 1.005-2.386 1.507-3.976 1.507-1.786 0-3.28-.627-4.478-1.883-1.2-1.255-1.8-3.055-1.8-5.4z"
                              ></path>
                            </svg>
                            <div className="best-review-content-wrapper">
                              <strong className="social-profile-txt-salmon">
                                MOST HELPFUL REVIEW
                              </strong>
                              <p className="social-profile-txt-light best-review-content">
                                {profile.best_review.review}
                              </p>
                            </div>
                            <svg
                              className="review-svg-quote-2"
                              xmlns="http://www.w3.org/2000/svg"
                              width="28"
                              height="23"
                              viewBox="0 0 28 23"
                            >
                              <path
                                fill="#D0CDD0"
                                fill-rule="evenodd"
                                d="M0 5.148c0-1.423.53-2.637 1.59-3.64C2.65.5 3.976 0 5.566 0c1.786 0 3.28.628 4.48 1.883 1.198 1.256 1.798 3.056 1.798 5.4 0 2.4-.362 4.56-1.088 6.487-.725 1.925-1.674 3.452-2.846 4.582-1.172 1.13-2.385 2.023-3.64 2.68-1.257.655-2.54 1.066-3.85 1.234v-4.018c1.84-.42 3.36-1.437 4.56-3.055 1.2-1.62 1.828-3.377 1.884-5.274-.42.278-.99.418-1.716.418-1.48 0-2.707-.468-3.683-1.402C.488 8 0 6.738 0 5.148zm16 0c0-1.423.53-2.637 1.59-3.64C18.65.5 19.976 0 21.566 0c1.786 0 3.28.628 4.48 1.883 1.198 1.256 1.798 3.056 1.798 5.4 0 2.4-.362 4.56-1.088 6.487-.725 1.925-1.674 3.452-2.846 4.582-1.172 1.13-2.385 2.023-3.64 2.68-1.257.655-2.54 1.066-3.85 1.234v-4.018c1.84-.42 3.36-1.437 4.56-3.055 1.2-1.62 1.828-3.377 1.884-5.274-.42.278-.99.418-1.716.418-1.48 0-2.707-.468-3.683-1.402C16.488 8 16 6.738 16 5.148z"
                              ></path>
                            </svg>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>

                <div>
                  {/*  */}

                  <div className="social-profile-contact-bar-mobile-container">
                    <div className="social-profile-contact-bar-mobile-title-container">
                      <img src={require("./images/availability.svg")} alt="" />
                      {profile.law_title ? (
                        <strong className="social-profile-txt-red">
                          CONNECT WITH {profile.law_title.toUpperCase()}
                        </strong>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="social-profile-contact-form-btn-wrapper">
                      <button
                        type="submit"
                        onClick={() => {
                          viewBookingTimes(false);
                          viewCallForm(true);
                        }}
                        className="social-profile-contact-button"
                      >
                        <Icon.Phone
                          className="phone-icon"
                          size={28}
                          strokeWidth={2}
                          color={"white"}
                        />{" "}
                        Call
                      </button>
                      <button
                        type="submit"
                        onClick={() => {
                          viewBookingTimes(false);
                          viewContactForm(true);
                        }}
                        className="social-profile-contact-button"
                      >
                        <Icon.Mail
                          className="phone-icon"
                          size={28}
                          strokeWidth={2}
                          color={"white"}
                        />{" "}
                        Message
                      </button>
                    </div>
                  </div>

                  {bookingTimes && (
                    <div className="social-profile-contact-container">
                      <div className="social-profile-contact-title-container">
                        <img
                          src={require("./images/availability.svg")}
                          alt=""
                        />
                        {profile.law_title ? (
                          <strong className="social-profile-txt-red">
                            CONNECT WITH {profile.law_title.toUpperCase()}
                          </strong>
                        ) : (
                          ""
                        )}
                      </div>
                      {lawFirm.office_hours ? (
                        <div>
                          <strong className="social-profile-txt-dark">
                            Office Hours
                          </strong>
                          <div
                            className="social-profile-contact-hours-container"
                            ref={contactHoursRef}
                          >
                            <div className="social-profile-contact-hours-text-container">
                              <p className="social-profile-txt-light">Mon.</p>
                              <hr />
                              <p className="social-profile-txt-light">{`${lawFirm.office_hours.monday.from} - ${lawFirm.office_hours.monday.to}`}</p>
                            </div>
                            <div className="social-profile-contact-hours-text-container">
                              <p className="social-profile-txt-light">Tue.</p>
                              <hr />
                              <p className="social-profile-txt-light">{`${lawFirm.office_hours.tuesday.from} - ${lawFirm.office_hours.tuesday.to}`}</p>
                            </div>
                            <div className="social-profile-contact-hours-text-container">
                              <p className="social-profile-txt-light">Wed.</p>
                              <hr />
                              <p className="social-profile-txt-light">{`${lawFirm.office_hours.wednesday.from} - ${lawFirm.office_hours.wednesday.to}`}</p>
                            </div>
                            <div className="social-profile-contact-hours-text-container">
                              <p className="social-profile-txt-light">Thu.</p>
                              <hr />
                              <p className="social-profile-txt-light">{`${lawFirm.office_hours.thursday.from} - ${lawFirm.office_hours.thursday.to}`}</p>
                            </div>
                            <div className="social-profile-contact-hours-text-container">
                              <p className="social-profile-txt-light">Fri.</p>
                              <hr />
                              <p className="social-profile-txt-light">{`${lawFirm.office_hours.friday.from} - ${lawFirm.office_hours.friday.to}`}</p>
                            </div>
                            <div className="social-profile-contact-hours-text-container">
                              <p className="social-profile-txt-light">Sat.</p>
                              <hr />
                              <p className="social-profile-txt-light">{`${lawFirm.office_hours.saturday.from} - ${lawFirm.office_hours.saturday.to}`}</p>
                            </div>
                            <div className="social-profile-contact-hours-text-container">
                              <p className="social-profile-txt-light">Sun.</p>
                              <hr />
                              <p className="social-profile-txt-light">{`${lawFirm.office_hours.sunday.from} - ${lawFirm.office_hours.sunday.to}`}</p>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <p>Availability timing not currently set</p>
                      )}
                      <div className="social-profile-contact-form-btn-wrapper">
                        <button
                          type="submit"
                          onClick={() => {
                            viewBookingTimes(false);
                            viewCallForm(true);
                          }}
                          className="social-profile-contact-button"
                        >
                          <Icon.Phone
                            className="phone-icon"
                            size={28}
                            strokeWidth={2}
                            color={"white"}
                          />{" "}
                          Call
                        </button>
                        <button
                          type="submit"
                          onClick={() => {
                            viewBookingTimes(false);
                            viewContactForm(true);
                          }}
                          className="social-profile-contact-button"
                        >
                          <Icon.Mail
                            className="phone-icon"
                            size={28}
                            strokeWidth={2}
                            color={"white"}
                          />{" "}
                          Message
                        </button>
                      </div>
                    </div>
                  )}

                  {contactForm && (
                    <div className="social-profile-contact-msg-container">
                      <div className="social-profile-contact-title-container">
                        <img
                          src={require("./images/availability.svg")}
                          alt=""
                        />
                        {profile.law_title ? (
                          <strong className="social-profile-txt-red">
                            CONNECT WITH {profile.law_title.toUpperCase()}
                          </strong>
                        ) : (
                          ""
                        )}
                      </div>

                      <form>
                        <div className="social-profile-contact-hours-container">
                          <div className="social-profile-flex-row">
                            <div className="input-container">
                              <p className="social-profile-txt-light">
                                First Name
                              </p>
                              <input
                                className="lw-portal-text-input text-field-name"
                                placeholder=""
                                name="firstName"
                                type="text"
                                value={inputs.firstName}
                                onChange={handleInputChange}
                                autoComplete="off"
                              />
                            </div>

                            <div className="input-container">
                              <p className="social-profile-txt-light">Phone</p>
                              <input
                                className="lw-portal-text-input text-field-time"
                                placeholder=""
                                type="text"
                                name="phoneNumber"
                                value={inputs.phoneNumber}
                                onChange={handleInputChange}
                                autoComplete="off"
                              />
                            </div>
                          </div>

                          <div className="sp-booking-date-container">
                            <div className="input-container">
                              <p className="social-profile-txt-light">Email</p>
                              <input
                                className="lw-portal-text-input text-field-email"
                                placeholder=""
                                name="email"
                                type="email"
                                value={inputs.email}
                                onChange={handleInputChange}
                                autoComplete="off"
                              />
                            </div>
                          </div>

                          <div style={{ marginTop: "10px" }}>
                            <TextAreaField
                              className="modal-text-area-field text-field-msg"
                              title="Message"
                              placeholder=""
                              name="message"
                              type="text"
                              value={inputs.message}
                              onChange={handleInputChange}
                              autoComplete="off"
                            />
                          </div>
                        </div>
                        <div className="social-profile-contact-form-btn-wrapper">
                          <button
                            type="button"
                            onClick={() => {
                              viewBookingTimes(true);
                              viewContactForm(false);
                            }}
                            className="social-profile-back-button"
                          >
                            <Icon.ArrowLeft
                              className="phone-icon"
                              size={28}
                              strokeWidth={2}
                              color={"#402224"}
                            />{" "}
                            Back
                          </button>
                          <button
                            type="button"
                            onClick={() => {
                              onSubmit(inputs);
                            }}
                            className="social-profile-contact-button"
                          >
                            <Icon.Mail
                              className="phone-icon"
                              size={28}
                              strokeWidth={2}
                              color={"white"}
                            />
                            Message
                          </button>
                        </div>
                      </form>
                      <p className="consent-txt social-profile-txt-light">
                        By submitting your contact information, you agree to our
                        Terms of Service and Privacy Policy.
                      </p>
                    </div>
                  )}

                  {callForm && (
                    <div className="social-profile-contact-msg-container">
                      <div className="social-profile-contact-title-container">
                        <img
                          src={require("./images/availability.svg")}
                          alt=""
                        />
                        {profile.law_title ? (
                          <strong className="social-profile-txt-red">
                            CONNECT WITH {profile.law_title.toUpperCase()}
                          </strong>
                        ) : (
                          ""
                        )}
                      </div>

                      <form>
                        <div
                          className="social-profile-contact-hours-container"
                          style={{ marginBottom: "15px" }}
                        >
                          <div className="social-profile-flex-row">
                            <div className="input-container">
                              <p className="social-profile-txt-light">
                                First Name
                              </p>
                              <input
                                className="lw-portal-text-input text-field-name"
                                placeholder=""
                                name="firstName"
                                type="text"
                                value={inputs.firstName}
                                onChange={handleInputChange}
                                autoComplete="off"
                              />
                            </div>

                            <div className="input-container">
                              <p className="social-profile-txt-light">Phone</p>
                              <input
                                className="lw-portal-text-input text-field-time"
                                placeholder=""
                                type="text"
                                name="phoneNumber"
                                value={inputs.phoneNumber}
                                onChange={handleInputChange}
                                autoComplete="off"
                              />
                            </div>
                          </div>

                          <div className="sp-booking-date-container">
                            <div className="input-container">
                              <p className="social-profile-txt-light">Email</p>
                              <input
                                className="lw-portal-text-input text-field-email"
                                placeholder=""
                                name="email"
                                type="email"
                                value={inputs.email}
                                onChange={handleInputChange}
                                autoComplete="off"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="social-profile-contact-form-btn-wrapper">
                          <button
                            type="button"
                            onClick={() => {
                              viewBookingTimes(true);
                              viewCallForm(false);
                            }}
                            className="social-profile-back-button"
                          >
                            <Icon.ArrowLeft
                              className="phone-icon"
                              size={28}
                              strokeWidth={2}
                              color={"#402224"}
                            />{" "}
                            Back
                          </button>
                          <button
                            type="button"
                            onClick={() => {
                              onCallSubmit(inputs);
                            }}
                            className="social-profile-contact-button"
                          >
                            <Icon.Phone
                              className="phone-icon"
                              size={28}
                              strokeWidth={2}
                              color={"white"}
                            />
                            Call
                          </button>
                        </div>
                      </form>
                      <p className="consent-txt social-profile-txt-light">
                        By submitting your contact information, you agree to our
                        Terms of Service and Privacy Policy.
                      </p>
                    </div>
                  )}

                  {successCall && (
                    <div className="profile__successMsgContainer social-profile-contact-container social-profile-contact-success-container">
                      <div className="profile__successMsg sp-contact-msg-container">
                        <img
                          className="profile__successMsg__icon"
                          src={require("./images/party.svg")}
                          alt=""
                        />
                        <h4 className="profile__successMsg__txt social-profile-txt-dark">{`You can call ${profile.first_name} at:`}</h4>
                        <a
                          className="social-profile-call-trigger-a-wrapper"
                          href="tel:123-456-7890"
                        >
                          <button className="social-profile-call-trigger-btn social-profile-txt-dark profile__successMsg__shareProfileBtn">
                            <Icon.Phone
                              className="phone-icon"
                              size={28}
                              strokeWidth={2}
                              color={"#402224"}
                              style={{ marginRight: "10px" }}
                            />
                            {profile.phone_number}
                          </button>
                        </a>
                        <CopyToClipboard
                          text={`https://app.counselconnect.ca/profile/${profile.handle}`}
                        >
                          <a className="social-profile-call-trigger-a-wrapper">
                            <button
                              aria-label={copyProfileLink}
                              data-microtip-position="left"
                              role="tooltip"
                              onClick={() => {
                                setCopyProfile("Copied 🎉");
                              }}
                              className="social-profile-call-trigger-btn social-profile-txt-dark"
                            >
                              <Icon.Link
                                className="phone-icon"
                                size={28}
                                strokeWidth={2}
                                color={"#402224"}
                                style={{
                                  marginRight: "10px",
                                }}
                              />
                              Share Profile
                            </button>
                          </a>
                        </CopyToClipboard>
                        <p className="profile__successMsg__bottomTxt social-profile-txt-light">{`We have sent you ${profile.first_name}'s information to your email for future reference.`}</p>
                      </div>
                    </div>
                  )}

                  {successMessage && (
                    <div className="profile__successMsgContainer social-profile-contact-container social-profile-contact-success-container">
                      <div className="profile__successMsg sp-contact-msg-container ">
                        <img
                          className="profile__successMsg__icon"
                          src={require("./images/party.svg")}
                        />
                        <h4 className="profile__successMsg__txt  social-profile-txt-dark">{`Your message has been delivered succesfully!`}</h4>
                        <CopyToClipboard
                          text={`https://app.counselconnect.ca/profile/${profile.handle}`}
                        >
                          <a className="profile__successMsg__shareProfileBtn social-profile-call-trigger-a-wrapper ">
                            <button
                              aria-label={copyProfileLink}
                              data-microtip-position="left"
                              role="tooltip"
                              onClick={() => {
                                setCopyProfile("Copied 🎉");
                              }}
                              className="social-profile-call-trigger-btn social-profile-txt-dark  "
                            >
                              <Icon.Link
                                className="phone-icon"
                                size={28}
                                strokeWidth={2}
                                color={"#402224"}
                                style={{
                                  marginRight: "10px",
                                }}
                              />
                              Share Profile
                            </button>
                          </a>
                        </CopyToClipboard>
                        <a
                          className="social-profile-call-trigger-a-wrapper "
                          href="https://counselconnect.ca"
                        >
                          <button className="social-profile-call-trigger-btn social-profile-txt-dark ">
                            <Icon.Home
                              className="phone-icon"
                              size={28}
                              strokeWidth={2}
                              color={"#402224"}
                              style={{ marginRight: "10px" }}
                            />
                            Return Home
                          </button>
                        </a>
                        <p className="profile__successMsg__bottomTxt social-profile-txt-light ">{`We have sent you ${profile.first_name}'s information to your email for future reference.`}</p>
                      </div>
                    </div>
                  )}

                  {/*  */}
                </div>
              </div>

              <div className="social-profile-cc-guarantee-container">
                <img
                  className="cc-guarantee-svg"
                  src={require("../law_search/images/cc-guarantee.svg")}
                  alt=""
                />
                <div className="social-profile-cc-guarantee-txt-wrapper">
                  <h4
                    className="social-profile-txt-dark social-profile-txt-bold"
                    style={{ color: "#0B1151" }}
                  >
                    The CounselConnect Guarantee
                  </h4>
                  <p
                    className="social-profile-txt-dark"
                    style={{ color: "#0B1151" }}
                  >
                    We only work with the top rated of {profile.law_title}s
                    within {lawFirm.city}.
                  </p>
                </div>
              </div>
            </div>

            <div className="social-profile-content-container">
              <div className="social-profile-content-1">
                <div className="social-profile-content-1-title-container">
                  <h5 className="social-profile-txt-dark">
                    What you need to know
                  </h5>
                  <div>
                    <img src={require("./images/oval.svg")} alt="" />
                    <hr />
                  </div>
                </div>

                <div className="social-profile-content-1-body">
                  <div className="social-profile-content-1-body-subtitle">
                    <strong className="social-profile-txt-dark">
                      Languages spoken
                    </strong>
                    <img src={require("./images/languages.svg")} alt="" />
                  </div>
                  <div>
                    <p className="social-profile-txt-light">
                      {profile.languages}
                    </p>
                  </div>
                </div>

                <div className="social-profile-content-1-body">
                  <div className="social-profile-content-1-body-subtitle">
                    <strong className="social-profile-txt-dark">
                      Areas of service
                    </strong>
                    <img src={require("./images/tool.svg")} alt="" />
                  </div>
                  {profile.law_services ? (
                    <div style={{ lineHeight: "1.2" }}>
                      {profile.law_services.map((field, index) => {
                        return (
                          <p key={index} className="social-profile-txt-light">
                            {field}
                          </p>
                        );
                      })}
                    </div>
                  ) : (
                    <div></div>
                  )}
                </div>

                {lawFirm.consultation_fee == "FREE" ? (
                  <div className="social-profile-content-1-body">
                    <div className="social-profile-content-1-body-subtitle">
                      <strong className="social-profile-txt-dark">
                        Consultation Fee
                      </strong>
                      <Icon.PhoneCall
                        className="phone-icon"
                        size={18}
                        strokeWidth={2}
                        color={"#FC6970"}
                        style={{ marginLeft: "10px" }}
                      />
                    </div>
                    <div>
                      <p className="social-profile-txt-light">
                        {lawFirm.consultation_fee}
                      </p>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
              {lawFirm.lat && lawFirm.lat ? (
                <div className="social-profile-content-1-office">
                  <div className="social-profile-content-1-title-container">
                    <h5 className="social-profile-txt-dark">Office</h5>
                    <div>
                      <img src={require("./images/oval.svg")} alt="" />
                      <hr />
                    </div>

                    <strong className="social-profile-txt-dark">
                      {lawFirm.address}
                    </strong>
                    <div className="address-sub-details">
                      <p className="social-profile-txt-light">
                        {lawFirm.city},
                      </p>
                      <p
                        className="social-profile-txt-light"
                        style={{ marginLeft: "10px" }}
                      >
                        {" "}
                        ON {lawFirm.postalcode}
                      </p>
                    </div>
                  </div>

                  <Map
                    google={google}
                    zoom={14}
                    style={mapStyles}
                    containerStyle={{ display: "block", position: "relative" }}
                    initialCenter={{ lat: lawFirm.lat, lng: lawFirm.lng }}
                    // disableDoubleClickZoom={true}
                    // disableDefaultUI={true}
                    maxZoom={14}
                    minZoom={14}
                  >
                    <Marker position={{ lat: lawFirm.lat, lng: lawFirm.lng }} />
                  </Map>

                  <div className="social-profile-content-1-body">
                    <div className="social-profile-content-1-body-subtitle" />
                    <div />
                  </div>
                </div>
              ) : (
                <div
                  className="social-profile-content-1-office"
                  style={{ height: "10%" }}
                >
                  <div className="social-profile-content-1-title-container">
                    <h5 className="social-profile-txt-dark">Office</h5>
                    <div>
                      <img src={require("./images/oval.svg")} alt="" />
                      <hr />
                    </div>

                    <span>{lawFirm.address}</span>
                    <div className="address-sub-details">
                      <span>{lawFirm.city},</span>
                      <span style={{ marginLeft: "10px" }}>
                        {" "}
                        ON {lawFirm.postalcode}
                      </span>
                    </div>
                  </div>
                </div>
              )}

              {profile.bio ? (
                <div className="social-profile-content-1">
                  <div className="social-profile-content-1-title-container">
                    <h5 className="social-profile-txt-dark">{`${profile.first_name} ${profile.last_name}`}</h5>
                    <div>
                      <img src={require("./images/oval.svg")} alt="" />
                      <hr />
                    </div>
                  </div>

                  <div className="social-profile-content-1-body">
                    <div className="social-profile-content-bio-container">
                      {profile.pictureURL ? (
                        <img
                          className="social-profile-view-image"
                          src={profile.pictureURL}
                          alt=""
                        />
                      ) : (
                        <div></div>
                      )}
                      <p className="social-profile-txt-light">{profile.bio}</p>
                    </div>
                  </div>
                </div>
              ) : (
                <div></div>
              )}

              <div className="social-profile-content-1-office">
                <div className="social-profile-content-1-title-container">
                  <h5 className="social-profile-txt-dark">
                    <strong>Awards</strong>
                  </h5>
                  <div>
                    <img src={require("./images/oval.svg")} alt="" />
                    <hr />
                  </div>
                </div>

                <div className="social-profile-award-body-wrap">
                  <div className="social-profile-content-education-body-subtitle">
                    <div className="social-profile-award-wrap mobile-hide">
                      <div>
                        <img
                          style={{ width: "40px" }}
                          src={require("../law_public_profile/images/trustHeart.svg")}
                          alt=""
                        />
                        <strong className="social-profile-txt-red">
                          CounselConnect Trusted
                          <a
                            aria-label={`This ${profile.law_title} has over 3 years of experience and no history of malpractice!`}
                            data-microtip-position="top"
                            role="tooltip"
                          >
                            <Icon.Info
                              className="phone-icon mobile-hide"
                              size={18}
                              strokeWidth={2}
                              color={"#DFD1D1"}
                              style={{ marginLeft: "10px" }}
                            />
                          </a>
                        </strong>
                      </div>
                      {profile.reviews_avg_rating &&
                        profile.reviews_avg_rating >= 4 && (
                          <div className="cc-cert-wrap">
                            <img
                              style={{ width: "35px" }}
                              src={require("../law_public_profile/images/trusthand.svg")}
                              alt=""
                            />
                            <strong className="social-profile-txt-red">
                              CounselConnect Certified
                              <a
                                aria-label={`This ${profile.law_title} has over 3 years of experience, 4+ reviews rating average, and no history of malpractice!`}
                                data-microtip-position="top"
                                role="tooltip"
                              >
                                <Icon.Info
                                  className="phone-icon mobile-hide"
                                  size={18}
                                  strokeWidth={2}
                                  color={"#DFD1D1"}
                                  style={{ marginLeft: "10px" }}
                                />
                              </a>
                            </strong>
                          </div>
                        )}
                    </div>

                    <div className="social-profile-award-wrap desktop-hide">
                      <div>
                        <img
                          style={{ width: "40px" }}
                          src={require("../law_public_profile/images/trustHeart.svg")}
                          alt=""
                        />
                        <strong className="social-profile-txt-red">
                          CounselConnect Trusted
                        </strong>
                      </div>
                      {profile.reviews_avg_rating &&
                        profile.reviews_avg_rating >= 4 && (
                          <div className="cc-cert-wrap">
                            <img
                              style={{ width: "35px" }}
                              src={require("../law_public_profile/images/trusthand.svg")}
                              alt=""
                            />
                            <strong className="social-profile-txt-red">
                              CounselConnect Certified
                            </strong>
                          </div>
                        )}
                    </div>
                  </div>
                </div>
              </div>
              {/* 
      <div style={{marginBottom: '20px'}}>
        <button className={showEmpRev && !showFirmRev ? "view-reviews-btn" : "view-reviews-btn btn-off"} onClick={()=>{setShowEmpRev(true); setShowFirmRev(false)}}>{profile.first_name}'s Reviews</button>
        <button className={showFirmRev && !showEmpRev ? "view-reviews-btn" : "view-reviews-btn btn-off"} style={{marginLeft: '20px'}} onClick={()=>{setShowFirmRev(true); setShowEmpRev(false)}}>Law Firm's Reviews</button>
    </div> */}

              {showEmpRev && !showFirmRev && (
                <div>
                  {profile.reviews && profile.reviews.length > 0 ? (
                    <ReviewsSection profile={profile} />
                  ) : (
                    <div className="reviews-container">
                      <div className="social-profile-reviews-header-wrap">
                        <div className="valid-reviews-container">
                          <h5 className="social-profile-txt-dark">
                            <strong>0 Detailed Reviews</strong>
                          </h5>

                          <small className="social-profile-txt-light">
                            All reviews are the subjective opinions of a client
                            and not of CounselConnect.
                          </small>
                        </div>
                        <div className="social-profile-line-wrap">
                          <img src={require("./images/oval.svg")} alt="oval" />
                          <hr />
                        </div>
                      </div>

                      <div className="no-reviews-container">
                        <p className="social-profile-txt-light">
                          This practitioner hasn't received any reviews from
                          clients yet!
                        </p>
                      </div>
                    </div>
                  )}
                </div>
              )}

              {/* {  showFirmRev && !showEmpRev && 

<div>
{
      lawFirm.reviews && lawFirm.reviews.length > 0 ?
            
        <FirmsReviewsSection profileId={lawFirm._id}  />
        
        : 
          
    <div className="reviews-container" >
            <div className="social-profile-reviews-header-wrap">
              <div className="valid-reviews-container">
                <h5 className="social-profile-txt-dark">
                  <strong>0 Detailed Reviews</strong>
                  </h5>
              
                <small className="social-profile-txt-light">All reviews are the subjective opinions of a client and not of CounselConnect.</small>
                </div>
              <div className="social-profile-line-wrap">
                  <img src={require('./images/oval.svg')} alt="oval" />
                  <hr />
              </div>
            </div>
          
            <div className="no-reviews-container">
              <p className="social-profile-txt-light">This practitioner hasn't received any reviews from clients yet!</p>
            </div>
          
      </div>    
}
</div>

} */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

FirmEmpLawProfile.propTypes = {
  getCurrentProfile: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  profile: state.profile,
  errors: state.errors,
});

export default connect(mapStateToProps, { getCurrentProfile })(
  withRouter(
    GoogleApiWrapper({ apiKey: "AIzaSyCWowVxHSrKoG4tgBqD31S0bLWnr36ynr0" })(
      withAlert()(FirmEmpLawProfile)
    )
  )
);
