import React, {
  useState,
  useEffect,
  lazy,
  Suspense,
  useRef,
  useContext,
} from "react";
import { withAlert } from "react-alert";
import Select from "react-select";
import http from "../../../utils/http";
import useForm from "../../../hooks/useForm";
import ProfileItem from "../law_profile_cards/ProfileItem";
import TextField from "../../reusable/text_field/TextField.jsx";
import BackBtn from "./BackBtn";
import { motion } from "framer-motion";
import { Search } from "react-feather";
import RatingStar from "../law_public_profile/images/star.svg";
import trustedIcon from "./images/trust-badge.svg";
import "./location.scss";
import "./results.scss";
import "./searchLoading.scss";
import { Link } from "react-router-dom";
import * as Icon from "react-feather";

import environment from "../../../utils/environment";

import Spinner from "../../reusable/spinner/spinner.gif";
import { FirmProfileContext } from "../../../context/FirmProfileContext";

const SolePracResultsList = lazy(() =>
  import("./sole_prac_results/SolePracResultsList.jsx")
);
const ResultsGuaranteeBanner = lazy(() =>
  import("../../reusable/search/ResultsGuaranteeBanner.jsx")
);
const LawFirmResultsList = lazy(() =>
  import("./law_firm_results/LawFirmResultsList.jsx")
);

const arr = [];
// const type = 'Law Firm';

const sortByOpitons = [
  { value: "Rating", label: "Rating" },
  { value: "Reviews", label: "Reviews" },
  // { value: "Price", label: "Price" },
  { value: "Distance", label: "Distance" },
];

const Results = (props) => {
  const { coordinates, city, status, field, gender, type } = props.match.params;

  const [soleProfiles, setSoleProfiles] = useState([{}]);
  const [employeeProfiles, setEmployeeProfiles] = useState([{}]);
  const [lawFirms, setFirmInfo] = useState([]);
  const [sortedFirmsList, setSortedFirmsList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [bannerView, hideBanner] = useState(true);
  const [isChecking, setChecking] = useState(false);

  const [caseInfo, setCaseInfo] = useState({});
  const topOfContainer = useRef(null);

  const [openTopicsDropdown, setOpenTopicsDropdown] = useState(false);
  const topicOpRef = useRef(null);
  const [value, setValue] = useState("Distance");
  const FIRM_PROFILE_CONTEXT = useContext(FirmProfileContext);

  const sortResults = () => {
    try {
      let sortBy = value;

      if (sortedFirmsList.length > 0) {
        console.log("sortedFirmsList", sortedFirmsList);

        if (sortBy == "Rating") {
          const sortedList = sortByAvgRating(sortedFirmsList);
          setSortedFirmsList(sortedList);
        }

        if (sortBy == "Reviews") {
          const sortedList = sortByNumOfReviews(sortedFirmsList);
          setSortedFirmsList(sortedList);
        }

        if (sortBy == "Distance") {
          sortDistances(sortedFirmsList);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  const sortByAvgRating = (firmList) => {
    const compareAvgRating = (a, b) => {
      const avgRatingA = parseFloat(a.firmProfile.reviews_avg_rating);
      const avgRatingB = parseFloat(b.firmProfile.reviews_avg_rating);
      const numOfReviewsA = a.firmProfile.num_of_reviews;
      const numOfReviewsB = b.firmProfile.num_of_reviews;

      if (avgRatingA === avgRatingB) {
        if (
          typeof numOfReviewsA === "undefined" &&
          typeof numOfReviewsB === "undefined"
        ) {
          return 0;
        } else if (typeof numOfReviewsA === "undefined") {
          return 1;
        } else if (typeof numOfReviewsB === "undefined") {
          return -1;
        } else {
          return numOfReviewsB - numOfReviewsA; // Sort by num_of_reviews in descending order
        }
      } else {
        return avgRatingB - avgRatingA; // Sort by reviews_avg_rating in descending order
      }
    };

    const firmsWithReviews = firmList.filter(
      (firm) => typeof firm.firmProfile.reviews_avg_rating === "string"
    );
    const firmsWithoutReviews = firmList.filter(
      (firm) => typeof firm.firmProfile.reviews_avg_rating !== "string"
    );

    const sortedFirmsWithReviews = firmsWithReviews.sort(compareAvgRating);

    return [...sortedFirmsWithReviews, ...firmsWithoutReviews];
  };

  const sortByNumOfReviews = (firmList) => {
    const compareNumOfReviews = (a, b) => {
      const numOfReviewsA = a.firmProfile.num_of_reviews;
      const numOfReviewsB = b.firmProfile.num_of_reviews;

      if (
        typeof numOfReviewsA === "undefined" &&
        typeof numOfReviewsB === "undefined"
      ) {
        return 0;
      } else if (typeof numOfReviewsA === "undefined") {
        return 1;
      } else if (typeof numOfReviewsB === "undefined") {
        return -1;
      } else {
        return numOfReviewsB - numOfReviewsA; // Sort in descending order
      }
    };

    const sortedFirmList = firmList.sort(compareNumOfReviews);

    const firmsWithoutNumOfReviews = sortedFirmList.filter((firm) =>
      isNaN(firm.firmProfile.num_of_reviews)
    );

    const firmsWithNumOfReviews = sortedFirmList.filter(
      (firm) => !isNaN(firm.firmProfile.num_of_reviews)
    );

    return firmsWithNumOfReviews.concat(firmsWithoutNumOfReviews);
  };

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      if (
        openTopicsDropdown &&
        topicOpRef.current &&
        !topicOpRef.current.contains(e.target)
      ) {
        setOpenTopicsDropdown(false);
      }
    };

    const eventListenerType = "mousedown";

    document.addEventListener(eventListenerType, checkIfClickedOutside);

    return () => {
      document.removeEventListener(eventListenerType, checkIfClickedOutside);
    };
  }, [openTopicsDropdown]);

  const sortBy = (
    <div className="results__sortBy__dropdown">
      <div
        className="results__sortBy__dropdown__toggle "
        onClick={() => {
          setOpenTopicsDropdown(true);
        }}
      >
        <div className="results__sortBy__dropdown__toggle__iconWrap">
          {value == "Rating" && (
            <Icon.Star color="#000" width="20" style={{ marginLeft: 5 }} />
          )}

          {value == "Reviews" && (
            <Icon.ThumbsUp color="#000" width="20" style={{ marginLeft: 5 }} />
          )}

          {value == "Price" && (
            <Icon.DollarSign
              color="#000"
              width="20"
              style={{ marginLeft: 5 }}
            />
          )}

          {value == "Distance" && (
            <Icon.MapPin color="#000" width="20" style={{ marginLeft: 5 }} />
          )}
          <span className="results__sortBy__dropdown__toggle__txt">
            {value}
          </span>
        </div>
        <Icon.ChevronDown color="#000" width="20" style={{ marginLeft: 5 }} />
      </div>

      {openTopicsDropdown && (
        <div className="results__sortBy__dropdown__options" ref={topicOpRef}>
          <div
            className="flex-column dropdown__txtWrap"
            optionClassName="needsclick"
          >
            <div className="flex-column ">
              {sortByOpitons.map((topic, index) => {
                return (
                  <div
                    key={index}
                    className="dropdown__iconOptionWrap "
                    onClick={() => {
                      setValue(topic.label);
                      setOpenTopicsDropdown(false);
                    }}
                  >
                    {topic.label == "Rating" && (
                      <Icon.Star
                        color="#000"
                        width="20"
                        style={{ marginLeft: 5 }}
                      />
                    )}

                    {topic.label == "Price" && (
                      <Icon.DollarSign
                        color="#000"
                        width="20"
                        style={{ marginLeft: 5 }}
                      />
                    )}

                    {topic.label == "Reviews" && (
                      <Icon.ThumbsUp
                        color="#000"
                        width="20"
                        style={{ marginLeft: 5 }}
                      />
                    )}

                    {topic.label == "Distance" && (
                      <Icon.MapPin
                        color="#000"
                        width="20"
                        style={{ marginLeft: 5 }}
                      />
                    )}
                    <span className="createPostModal_optionTxt">
                      {topic.label}
                    </span>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )}
    </div>
  );

  const getLawFirmInfo = async (listOfEmpProfiles) => {
    try {
      const response = await http.post(
        `${environment.resolveApi().rest}/search_engine/firm/list`,
        { listOfEmpProfiles }
      );
      setFirmInfo(response.data);
      console.log(response.data);
      await getTravelDistance(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const [showLoading, setShowLoading] = useState(true);

  const getTravelDistance = async (firmProfiles) => {
    try {
      const origin = [`${coordinates}`];
      let destination = [];
      let listOfProfiles = [];

      firmProfiles.map((profile) => {
        destination.push(`${profile.lat}, ${profile.lng}`);
        listOfProfiles.push({ firmProfile: profile });
      });

      const travelMode = "DRIVING";

      const response = await http.post(
        `${environment.resolveApi().rest}/search_engine/getTravelDistance`,
        { listOfProfiles, origin, destination, travelMode }
      );
      await sortDistances(response.data);
    } catch (error) {
      console.log(error);
      if (error.distance) {
        props.history.push({
          pathname: "/search/fail",
          state: { city, status, gender, field, type, resetComponent: true },
        });
      }
    }
  };

  const sortDistances = async (distancesList) => {
    try {
      const response = await http.post(
        `${environment.resolveApi().rest}/search_engine/sortDistances`,
        { distancesList }
      );

      setSortedFirmsList(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getCaseInfo = () => {
    const local_search = localStorage.getItem("search_save");
    const local_search_parsed = JSON.parse(local_search);

    setCaseInfo(local_search_parsed);
  };

  useEffect(() => {
    const getEmployeeList = async () => {
      try {
        const response = await http.get(
          `${
            environment.resolveApi().rest
          }/profile/searchEmployees/${city}/${status}/${field}/${gender}/active`
        );
        setEmployeeProfiles(response.data.employee_profiles);
        getLawFirmInfo(response.data.employee_profiles);
      } catch (error) {
        console.log(error);
      }
    };

    setLoading(true);
    getCaseInfo();
    getEmployeeList();

    setLoading(false);

    FIRM_PROFILE_CONTEXT.setDetailedView(false);

    const timer = setTimeout(() => {
      setShowLoading(false);
    }, 500);
    return () => clearTimeout(timer);

    // Sort list of law firms from nearest to the client's distance
  }, [setLoading, coordinates, city, status, field, gender, type]);

  const scrollToTop = () => {
    let options = { behaviour: "smooth", block: "center", inline: "start" };

    console.log({ topOfContainer });

    // we want the React lifecycle to complete before scrolling
    setTimeout(() => {
      if (topOfContainer) {
        console.log("testing");

        topOfContainer.current.scrollIntoView(options);
      }
    }, 0);
  };

  useEffect(() => {
    scrollToTop();
  }, []); // Empty dependency array ensures the effect only runs once on mount

  useEffect(() => {
    sortResults();
  }, [value]);

  return (
    <div>
      <div
        className=""
        ref={topOfContainer}
        style={{ width: 100, height: 10 }}
      ></div>

      {showLoading ? (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <div className="search-loading-container">
            <div className="search-loading-header">
              <img
                className="search-loading-cc-logo"
                src={require("../../auth/images/logo.svg")}
                alt="counsel-connect-logo"
              />
              <h5
                className="social-profile-txt-dark"
                style={{ fontWeight: "700" }}
              >
                Matching you with a {type} in {city}…
              </h5>
            </div>
            <div className="search-loading-body"></div>
          </div>
        </motion.div>
      ) : (
        <div className="">
          <div className="results__header">
            <div className="ccSearch__header">
              <a>
                <img
                  className="lawyer-search-navbar-logo mobile-show"
                  src={require("./images/cc-search-logo--blue.svg")}
                  alt="counsel-connect-log"
                />
              </a>
            </div>{" "}
          </div>

          <div className="search-header mobile-hide table-view">
            <a href="https://counselconnect.ca/">
              <img
                className="social-profile-nav-cc-logo"
                src={require("../law_search/images/cc-search-logo--blue.svg")}
                alt="counsel-connect-log"
              />
            </a>
          </div>
          {/* <div className="search-header"><a href="https://counselconnect.ca/"><img className="lawyer-search-logo" src={require('./images/cc-search-logo.svg')} alt="counsel-connect-log" /></a></div> */}
          <div className="searchResults">
            {/* <div className="searchResults__chatgpt">
              <Suspense fallback={<div>Loading...</div>}>
                <ResultsGuaranteeBanner
                  typeOfLawSelection={type}
                  citySelection={city}
                />
              </Suspense>

              <div className="searchResults__chatgpt__userMsgContainer">
                <span className="searchResults__chatgpt__role">You</span>
                <div className="searchResults__chatgpt__userMsg">
                  <span>{caseInfo.message}</span>
                </div>{" "}
              </div>

              <div className="searchResults__chatgpt__userMsgContainer">
                <span className="searchResults__chatgpt__role">ChatGPT</span>
                <div className="searchResults__chatgpt__aiMsg">
                  <span>{caseInfo.aiResponse}</span>
                </div>{" "}
              </div>

              <img
                className="searchResults__chatgpt__poweredbyIcon"
                src={require("./images/icon_poweredby.svg")}
                alt="counsel-connect-log"
              />
            </div> */}
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              style={{ backgroundColor: "#FFF" }}
            >
              <div className="Results">
                <div>
                  <div>
                    {type === "Law Firm" ? (
                      <div className="results__toggleContainer">
                        <h5 className="social-profile-txt-dark number-of-resulted-firms">
                          We found you{" "}
                          {lawFirms.length > 0 ? lawFirms.length : ""}{" "}
                          <strong>
                            {lawFirms.length > 1 ? `Law firms` : "Law firm"}
                          </strong>{" "}
                          for <strong>{field}</strong>
                        </h5>
                        <div className="mobile-hide">{sortBy}</div>
                      </div>
                    ) : (
                      <>
                        <h5 className="social-profile-txt-dark number-of-resulted-firms">
                          We found you{" "}
                          {soleProfiles.length > 0 ? soleProfiles.length : ""}{" "}
                          <strong>
                            {soleProfiles.length > 1 ? `${status}s` : status}
                          </strong>{" "}
                          for <strong>{field}</strong>
                        </h5>
                      </>
                    )}
                    <div className="results__sortByContainer">
                      <span className=" results__sortByTxt mobile-show-only">
                        Sort By
                      </span>
                      <div className="mobile-show-only">
                        <Select
                          options={sortByOpitons}
                          defaultValue={value}
                          // value={value}
                          onChange={(object) => {
                            setValue(object.value);
                          }}
                          styles={{
                            control: (baseStyles, state) => ({
                              ...baseStyles,
                              width: "200px",
                              marginLeft: 20,
                            }),
                          }}
                        />
                      </div>
                    </div>

                    <div className="firms-result-page-wrapper">
                      <div className="law-firms-boxes-wrap">
                        {type === "Law Firm" ? ( // IF -> LAW FIRM
                          <Suspense fallback={<div>Loading...</div>}>
                            <LawFirmResultsList
                              lawFirmsList={sortedFirmsList}
                              typeOfLawSelection={type}
                              citySelection={city}
                              lawFieldSelection={field}
                              employeeProfiles={employeeProfiles}
                              typeOfPractitioner={status}
                              {...props}
                            />
                          </Suspense>
                        ) : (
                          //ELSE -> SOLE PRACTITIONERS

                          <Suspense fallback={<div>Loading...</div>}>
                            <SolePracResultsList
                              solePractitonersList={sortedFirmsList}
                              typeOfLawSelection={type}
                              citySelection={city}
                            />
                          </Suspense>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </motion.div>
          </div>
        </div>
      )}
    </div>
  );
};

export default withAlert()(Results);
