import React from 'react';
import './location.scss';
import BackBtn from './BackBtn';
import { Info } from 'react-feather';
import Tooltip from 'react-tooltip-lite';
import microtip from 'microtip/microtip.css'
import { motion } from 'framer-motion'

import dateFormat from "dateformat";

const lawStatuses = [
  { id: 1, value: 'Lawyer' },
  { id: 2, value: 'Paralegal' },
];

let status = '';

const Status = (props) => {

  const { coordinates, city } = props.match.params;



  const onSubmitFields = () => {

    // save to localstorage
    const dataToSave = {
      pathname: `/search/${coordinates}/${city}/${status}`,
      date: dateFormat(new Date(), 'mmmm dd'),
      city: city,
      status: 'pending'
    }

    localStorage.setItem('search_save', JSON.stringify(dataToSave));

    props.history.push({ pathname: `/search/${coordinates}/${city}/${status}` });
  };




  const btnClick = (event) => {
    if (event) event.preventDefault();
    status = event.currentTarget.value;
    onSubmitFields();
  };




  return (
    <div>
      <div className="search-header lawyer-search-transparent-navbar"><a href="https://counselconnect.ca/"><img className="lawyer-search-navbar-logo" src={require('./images/cc-search-logo.svg')} alt="counsel-connect-log" /></a></div>

      <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} style={{ backgroundColor: "#f6f0f0" }} >

        {/* <BackBtn  importantProps={{ prevPath: prevPath, city }} history={props.history} /> */}

        <div className="Location" style={{ height: '35rem' }}>
          {/* <UserCheck size={70} color="#d0021b" style={{ marginBottom: "20px" }} /> */}

          <img className="location-search-header-icon" src={require('./images/lawyer.svg')} alt="" />

          <h4 className="social-profile-txt-dark lawyer-search-title">Would you like to see a Lawyer or Paralegal?</h4>
          <span className="social-profile-txt-light lawyer-search-title">Select the option that works best for you.</span>
          <div className="location-search-button-container">
            {lawStatuses.map((status, index) => (
              <div key={index} >
                <button className="status-search-lawyer-button" value={status.value} onClick={btnClick}>{status.value}
                  <span style={{ marginLeft: "10px" }}>
                  </span>
                </button>

              </div>
            ))}
          </div>
          <div className="location-page-num">2 of 5</div>
        </div>
      </motion.div>
    </div >

  );
};

export default Status;
