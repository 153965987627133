import React, { useState } from "react";
import "./location.scss";
import { motion } from "framer-motion";
import Tooltip from "react-tooltip-lite";

import BackBtn from "./BackBtn";
import { Info } from "react-feather";
import dateFormat from "dateformat";

import "./Categories.scss";
const lawyerFields = [
  { label: "Criminal Law", value: "Criminal Law" },
  { label: "Commercial Law", value: "Commercial Law" },
  { label: "Civil Law", value: "Civil Law" },
  { label: "Employment Law", value: "Employment Law" },
  { label: "Family Law", value: "Family Law" },
  // { label: "Immigration Law", value: "Immigration Law" },
  { label: "Real Estate", value: "Real Estate" },
  { label: "Personal Injury", value: "Personal Injury Law" },
  // { label: "Wills, Estates, Trusts", value: "Wills, Estates, Trusts" },
  // { label: "Environment Law", value: "Environment Law" },
  // { label: "Insurance Law", value: "Insurance Law" },
  // { label: "Administrative Law", value: "Administrative Law" },
  // { label: "Entertainment Law", value: "Entertainment Law" },
];

const paralegalFields = [
  { id: 1, value: "Traffic Tickets" },
  { id: 2, value: "Minor Offences" },
  { id: 3, value: "Landlord or Tenant" },
  { id: 4, value: "Employment Law" },
  { id: 5, value: "Small Claims" },
  { id: 6, value: "Immigration Law" },
];

const arr = [];

const Lawyer = (props) => {
  const { coordinates, city, status } = props.match.params;

  // if (!props.location.state) {
  //   props.history.push({ pathname: `/search` })
  // } else {
  //   var { clientLatLng } = props.location.state;

  //   console.log(city, status, clientLatLng);
  // }

  // if (!props.location.state) {
  //   props.history.push({ pathname: `/recommendation/reason` })
  // } else {
  //   var { city, clientLatLng, status, prevPath } = props.location.state;
  // }

  const [lawFields, setLawFields] = useState(arr);

  // const onSubmitFields = () => {

  //   if (arr.length === 0) {
  //     props.alert.error("Select a category!");
  //   } else {
  //     setLawFields(arr);

  //     // props.history.push({ pathname: `/search_by_gender`, state: { city: city, clientLatLng: clientLatLng, status: status, field: lawFields.pop(), prevPath: [...prevPath, props.location.pathname] } });
  //   }
  // };

  const onSubmitFields = () => {
    setLawFields(arr);

    if (lawFields.length > 0) {
      // save to localstorage
      const dataToSave = {
        pathname: `/search/${coordinates}/${city}/${status}/${
          lawFields[lawFields.length - 1]
        }`,
        date: dateFormat(new Date(), "mmmm dd"),
        city: city,
        status: "pending",
        service_request: lawFields[lawFields.length - 1],
      };

      localStorage.setItem("search_save", JSON.stringify(dataToSave));
      props.history.push({
        pathname: `/search/${coordinates}/${city}/${status}/${
          lawFields[lawFields.length - 1]
        }/Both/Law%20Firm`,
      });
    } else {
      // save to localstorage
      const dataToSave = {
        pathname: `/search/${coordinates}/${city}/${status}/${lawFields}`,
        date: dateFormat(new Date(), "mmmm dd"),
        city: city,
        status: "pending",
        service_request: lawFields,
      };

      localStorage.setItem("search_save", JSON.stringify(dataToSave));
      props.history.push({
        pathname: `/search/${coordinates}/${city}/${status}/${lawFields}/Both/Law%20Firm`,
      });
    }
  };

  const btnClick = (event) => {
    if (event) event.preventDefault();
    // const index = arr.indexOf(event.currentTarget.value);
    arr.push(event.currentTarget.value);
    onSubmitFields();
    // if (index > -1) {
    //   arr.splice(index, 1);
    // } else {
    //   arr.push(event.currentTarget.value);
    // }
  };

  return (
    <div>
      <div className="search-header lawyer-search-transparent-navbar">
        <a href="https://counselconnect.ca/">
          <img
            className="lawyer-search-navbar-logo"
            src={require("./images/cc-search-logo.svg")}
            alt="counsel-connect-log"
          />
        </a>
      </div>

      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        style={{ backgroundColor: "#f6f0f0" }}
      >
        {/* <BackBtn importantProps={{ prevPath: prevPath, city, status, field: lawFields.pop() }} history={props.history} /> */}
        <div className="Lawyer-Search">
          <img
            className="location-search-header-icon"
            src={require("./images/gradle.svg")}
            alt=""
          />

          <h4 className="social-profile-txt-dark lawyer-search-title">
            {status} Categories
          </h4>
          <span className="social-profile-txt-light lawyer-search-title">
            Select the law field that best fits your needs.
          </span>

          {status == "Lawyer" ? (
            <div className="law-field-options">
              {lawyerFields.map((field, index) => (
                <div key={index} className="status-search-lawyer-button-block">
                  <button
                    data-recording-click-props={`{"category":"${field.value}"}`}
                    type="submit"
                    className="status-search-lawyer-button"
                    onClick={btnClick}
                    key={index.id}
                    value={field.value}
                  >
                    {field.value}
                    <span style={{ marginLeft: "10px" }}></span>
                  </button>
                </div>
              ))}
            </div>
          ) : (
            <div className="law-field-options">
              {paralegalFields.map((field, index) => (
                <div key={index}>
                  <button
                    type="submit"
                    className="status-search-lawyer-button"
                    onClick={btnClick}
                    key={index.id}
                    value={field.value}
                  >
                    {field.value}
                    <span style={{ marginLeft: "10px" }}></span>
                  </button>
                </div>
              ))}
            </div>
          )}

          {/* <div className="location-page-num">2 of 2</div> */}
        </div>
      </motion.div>
    </div>
  );
};

export default Lawyer;
