import React, { useState, useEffect } from "react";
import TextField from "../../reusable/text_field/TextField.jsx";
import http from "../../../utils/http";
import useForm from "../../../hooks/useForm";
import { withAlert } from "react-alert";
import environment from "../../../utils/environment";

const SearchFail = (props) => {
  const [isMidSearch, setIsMidSeach] = useState(false);
  const [isCompleteSearch, setIsCompleteSearch] = useState(false);

  const [savedSearch, setSavedSearch] = useState({});

  if (!props.location.state) {
    props.history.push({
      pathname: "/recommendation/reason",
      state: { resetComponent: false },
    });
  } else {
    var { city, status, gender, field, type, coordinates } =
      props.location.state;
  }

  const onSubmit = async (inputs) => {
    if (!inputs.email || inputs.email.length === 0) {
      props.alert.error("Please enter in your Email!");
    }

    try {
      const payload = { email: inputs.email };
      await http.post(
        `${
          environment.resolveApi().rest
        }/mailing/getupdate/${status}/${field}/${city}/${gender}/${type}/${coordinates}`,
        payload
      );
      setShowForm(false);
      // props.history.push('/');
    } catch (error) {
      console.error("Sending Error!", error);
    }
  };

  const [showForm, setShowForm] = useState(true);
  const { inputs, handleInputChange, handleSubmit } = useForm(onSubmit);

  // useEffect(() => {

  //   const local_search = localStorage.getItem('search_save');
  //   const local_search_parsed = JSON.parse(local_search)

  //   if (local_search_parsed) {

  //     if (local_search_parsed.status == 'pending') {
  //       setIsMidSeach(true)
  //     } else {
  //       setIsCompleteSearch(true)

  //     }

  //     setSavedSearch(local_search_parsed)

  //   }

  // }, [])

  return (
    <div className="">
      <div className="lawyer-search-social-profile-mobile-navbar">
        <a href="https://counselconnect.ca/">
          <img
            className="lawyer-search-social-profile-mobile-navbar-img"
            src={require("../law_search/images/cc-single-logo.svg")}
            alt="counsel-connect-log"
          />
        </a>
      </div>

      <div className="search-header mobile-hide table-view">
        <a href="https://counselconnect.ca/">
          <img
            className="social-profile-nav-cc-logo"
            src={require("../law_search/images/cc-search-logo.svg")}
            alt="counsel-connect-log"
          />
        </a>
      </div>

      {showForm ? (
        <div className="result-empty-container">
          <div className="result-empty-title-wrapper">
            <h5 className="social-profile-txt-dark">
              Sorry, we couldn't find a practitioner with your search criteria:
            </h5>
            <strong className="social-profile-txt-light">
              {gender == "Both" ? "" : gender} {field} {status} in {city} -{" "}
              {type}
            </strong>
          </div>

          <form className="result-empty-form" onSubmit={handleSubmit}>
            <TextField
              className="login-portal-input"
              title="Enter in your email to get updated!"
              placeholder=""
              name="email"
              type="email"
              value={inputs.email}
              onChange={handleInputChange}
            />
            <button type="submit" className="edit-avail-update-button">
              Submit
            </button>
            <button
              onClick={() => {
                props.history.push({
                  pathname: "/recommendation/reason",
                  state: { resetComponent: true },
                });
              }}
              className="edit-avail-update-button edit-avail-return-button"
            >
              Return to Search
            </button>
          </form>

          <div className="search-fail-btn-wrap" style={{ marginTop: 30 }}>
            {/* <button className="status-search-lawyer-button law-type-btn search-btn-content"
              style={{ width: '100%', height: '100%', maxWidth: 400, padding: 50 }}
              onClick={() => { props.history.push({ pathname: '/recommendation/reason', state: { resetComponent: true } }); }}
            >

              <svg width="42px" height="36px" viewBox="0 0 52 36" version="1.1" style={{ marginBottom: 10 }}>

                <g id="CC-2021-Gold-Membership" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                  <g id="complete-previous-search" transform="translate(-919.000000, -378.000000)">
                    <rect id="Rectangle" stroke="#A38C8C" fill="#FFFFFF" x="734.5" y="331.5" width="436" height="226" rx="8"></rect>
                    <g id="Group" transform="translate(920.000000, 379.000000)" stroke="#D0021B" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.75">
                      <g id="plus-circle-(3)">
                        <circle id="Oval" cx="11.6666667" cy="11.6666667" r="11.6666667"></circle>
                        <line x1="11.6666667" y1="7" x2="11.6666667" y2="16.3333333" id="Path"></line>
                        <line x1="7" y1="11.6666667" x2="16.3333333" y2="11.6666667" id="Path"></line>
                      </g>
                      <g id="search-(1)" transform="translate(28.000000, 12.000000)">
                        <circle id="Oval" cx="9.33333333" cy="9.33333333" r="9.33333333"></circle>
                        <line x1="21.5" y1="21.5" x2="15.925" y2="15.925" id="Path"></line>
                      </g>
                    </g>
                  </g>
                </g>
              </svg>

              <span className="social-profile-txt-dark" style={{ fontSize: 16 }}>Start a new search</span>
              <small>Answer a new set of questions and get matched
                with the best Lawyers near you</small>
            </button> */}
          </div>
        </div>
      ) : (
        <div className="result-empty-container">
          <div className="result-empty-title-wrapper">
            <h5 className="social-profile-txt-dark">
              We have been notified of your request!
            </h5>
          </div>

          <button
            onClick={() => {
              props.history.push({
                pathname: "/recommendation/reason",
                state: { resetComponent: true },
              });
            }}
            className="edit-avail-update-button edit-avail-return-button"
          >
            Return to Search
          </button>
        </div>
      )}
    </div>
  );
};

export default withAlert()(SearchFail);
