import React, { useState, useEffect } from 'react';
import useForm from '../../../hooks/useForm';
import TextField from '../../reusable/text_field/TextField.jsx';
import SelectList from '../../reusable/select_list/SelectList';
import { withAlert } from "react-alert";
import Modal from '../../reusable/Modal';
import http from '../../../utils/http';
import environment from '../../../utils/environment';
import "./createClientModal.scss";

import { getCurrentProfile } from '../../../actions/profileActions';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// const case_statuses = [ { label: '* required', value: 0 }, { label: 'Waiting', value: 'waiting' }, { label: 'Pending', value: 'pending' }, ];


// Select field options for field of work
const paralegalCategories = [
    { label: '* Select Service', value: 0 },
    { label: 'Traffic Tickets', value: 'Traffic Tickets' },
    { label: 'Minor Offences', value: 'Minor Offences' },
    { label: 'Landlord or Tenant', value: 'Landlord or Tenant' },
    { label: 'Employment Law', value: 'Employment Law' },
    { label: 'Small Claims', value: 'Small Claims' },
    { label: 'Immigration Law', value: 'Immigration Law' },
  ];
  
  const lawyerCategories = [
    { label: '* Select Service', value: 0 },
    { label: 'Criminal Law', value: 'Criminal Law' },
    { label: 'Commercial Law', value: 'Commercial Law' },
    { label: 'Civil Law', value: 'Civil Law' },
    { label: 'Employment Law', value: 'Employment Law' },
    { label: 'Family Law', value: 'Family Law' },
    { label: 'Immigration Law', value: 'Immigration Law' },
    { label: 'Real Estate', value: 'Real Estate' },
    { label: 'Personal Injury', value: 'Personal Injury Law' },
  { label: 'Wills, Estates, Trusts', value: 'Wills, Estates, Trusts' },
  { label: 'Environment Law', value: 'Environment Law' },
  { label: 'Insurance Law', value: 'Insurance Law' },
  { label: 'Property Law', value: 'Property Law' },
  { label: 'Administrative Law', value: 'Administrative Law' },
  { label: 'Entertainment Law', value: 'Entertainment Law' },
  ];


const EditClientModal = (props) => {
    const {
        history,
        modalTitle,
        modalId,
        clientInfo
     } = props;

    
    
     const [profileStatus, setProfileStatus] = useState({})
  
     const onSubmit = async (inputs) => {
        
         try {
          
            const payload = { 
                clientId: clientInfo._id,
                first_name: inputs.first_name, 
                last_name: inputs.last_name,
                phone: inputs.phone,
                email: inputs.email,
                service: inputs.service,
                case_status: "waiting"
            };

            if ( !payload.first_name || !payload.last_name || !payload.phone || !payload.email || !payload.service ) {
                props.alert.error("Missing Input!");
                return console.error('missing inputs');
            }

            await http.post(`${environment.resolveApi().rest}/client/update`, payload);
            props.alert.success("Client Updated!");
            history.push('/client-list');
         } catch (error) {
             if(error.email) {
                props.alert.error(error.email);
             }
            if(error.message) {
                props.alert.error(error.message);
            }
            
            console.log(error);
         }
     }

     
     const { inputs, setInputs, handleInputChange, handleSubmit } = useForm(onSubmit);

 

  useEffect(() => {

    const getProfile = async () => {
      try {
        const response = await http.get(`${environment.resolveApi().rest}/profile/me`);
        setProfileStatus(response.data.profile.status);
      } catch (error) {
        console.log(error);
      }
    };
    
    getProfile();
    console.log(clientInfo)
    setInputs(clientInfo);
  
  }, [clientInfo, setInputs]);


    return (
        <div className="EditClientModal CreateClientModal">
            <Modal className="edit-avail-modal" title={modalTitle} modalId={modalId}>
                <div className="create-client-modal-outer-container">
                    <form className="create-client-modal-form" onSubmit={handleSubmit}>
                    <TextField
                        className="lw-portal-text-input"
                        title="First Name"
                        placeholder="* required"
                        name="first_name"
                        onChange={handleInputChange}
                        value={inputs.first_name}
                        lawPortal
                        autoComplete="off"
                    />
                    <TextField
                        className="lw-portal-text-input"
                        title="Last Name"
                        placeholder="* required"
                        name="last_name"
                        onChange={handleInputChange}
                        value={inputs.last_name}
                        lawPortal
                        autoComplete="off"
                    />
                    <TextField
                        className="lw-portal-text-input"
                        title="Phone Number"
                        placeholder="* required"
                        name="phone"
                        onChange={handleInputChange}
                        value={inputs.phone}
                        lawPortal
                        autoComplete="off"
                    />

                    <TextField
                        className="lw-portal-text-input"
                        title="Email"
                        placeholder="* required"
                        name="email"
                        onChange={handleInputChange}
                        value={inputs.email}
                        lawPortal
                        autoComplete="off"
                    />
              

                    <SelectList
                        className="law-title-input"
                        title="Law Title"
                        name="service"
                        value={inputs.service}
                        onChange={handleInputChange}
                        options={profileStatus === "Lawyer" ? lawyerCategories : paralegalCategories}
                        //   error={errors.status}
                        //   info="Lawyer or Paralegal?"
                        lawPortal
                    />
                    
                     <button className="dashboard-row-2-card-1-btn" type="submit">Update</button>
                    </form>
                </div>
            </Modal>
        </div>

    );
}



export default withAlert()(EditClientModal);