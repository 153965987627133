import React, { useState, useEffect, useCallback, useRef } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import { withRouter } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import { motion } from "framer-motion";
import { Provider } from "react-redux";
import { checkLogin } from "./actions/authActions";
import store from "./utils/store";
import Spinner from "./components/reusable/spinner/Spinner";
import SignUp from "./components/auth/SignUp";
import Login from "./components/auth/Login";
import SignUpRequest from "./components/auth/SignUpRequest";
import SearchFail from "./components/lawyer_search_app/law_search/SearchFail.jsx";
import Main from "./Main";
import PrivateRoute from "./components/reusable/PrivateRoute";
import Profile from "./components/lawyer_search_app/law_public_profile/SocialProfile";
import FirmEmpLawProfile from "./components/lawyer_search_app/law_public_profile/FirmEmpLawProfile";

import Location from "./components/lawyer_search_app/law_search/Location.jsx";
import Status from "./components/lawyer_search_app/law_search/Status.jsx";
import Lawyer from "./components/lawyer_search_app/law_search/Lawyer.jsx";
import Paralegal from "./components/lawyer_search_app/law_search/Paralegal.jsx";
import Gender from "./components/lawyer_search_app/law_search/Gender.jsx";
import Type from "./components/lawyer_search_app/law_search/Type.jsx";
import Results from "./components/lawyer_search_app/law_search/Results.jsx";

import LawFirm from "./components/lawyer_search_app/law_search/LawFirm.jsx";

import { positions, Provider as AlertProvider } from "react-alert";
import AlertTemplate from "react-alert-template-basic";
import PasswordResetRequest from "./components/auth/PasswordResetRequest.jsx";
import ResetPassword from "./components/auth/ResetPassword.jsx";

import http from "./utils/http";
import environment from "./utils/environment";
import UpgradeClientVersion from "./components/reusable/upgrade_version_modal/UpgradeClientVersion.jsx";
import { FirmProfileContext } from "./context/FirmProfileContext";

import ReactGA from "react-ga";
import { browserHistory } from "history";
import { useHistory } from "react-router";
import NoPremiumLogin from "./components/auth/no_premium_login/NoPremiumLogin";
import ExplainCase from "./components/lawyer_search_app/law_search/explain_case/ExplainCase";
import SearchCriteria from "./components/lawyer_search_app/law_search/search_criteria/SearchCriteria";
import SearchUrgency from "./components/lawyer_search_app/law_search/search_urgency/SearchUrgency";
import SearchAvailability from "./components/lawyer_search_app/law_search/search_availability/SearchAvailability";
import SearchIncome from "./components/lawyer_search_app/law_search/search_income/SearchIncome";

function initializeReactGA() {
  ReactGA.initialize("UA-156494975-3");
  ReactGA.pageview("/recommendation/reason");
}

// optional cofiguration
const options = {
  position: positions.TOP_RIGHT,
  timeout: 4000,
  offset: "50px",
  transition: "fade",
  containerStyle: {
    zIndex: 9999,
  },
};

const App = () => {
  const [isChecking, setChecking] = useState(true);

  const [detailedView, setDetailedView] = useState(false);

  const windowSize = useRef([window.innerWidth, window.innerHeight]);

  useEffect(() => {
    console.log(windowSize.current[0]);
    const initialValue = document.body.style.zoom;

    let windowWidth = windowSize.current[0];

    if (windowWidth > 767) {
      // Change zoom level on mount
      document.body.style.zoom = "75%";
    } else {
      // Change zoom level on mount
      document.body.style.zoom = "100%";
    }

    return () => {
      // Restore default value
      document.body.style.zoom = initialValue;
    };
  }, []);

  // check if the server is up
  useEffect(() => {
    (async function () {
      try {
        await store.dispatch(checkLogin());
      } catch (err) {
        console.log(err.message);
      }

      setChecking(false);
    })();

    // // DONT EVER REMOVE THE LINE BELOW ----------------------------------------------------- EVEN IN DEV.
    // // Run an setInterval every 15 seconds
    setInterval(() => {
      (async function () {
        const clientVersion = environment.resolveVersion().version;
        // Call API
        const response = await http.get(
          `${
            environment.resolveApi().rest
          }/admin/lawyersearch/version/${clientVersion}`
        );
        console.log(response.data);
        if (response.data == true) {
          console.log("NEW VERSION --- RELOADING CACHE NOW!");
          // Reload the current resources from the server
          alert(
            'Version update, if you were searching for a lawyer, click "OK" and please start over.'
          );
          window.location.reload(true);
        }
      })();
    }, 15000);

    initializeReactGA();
  }, []);

  if (isChecking) return <Spinner />;

  return (
    <AlertProvider template={AlertTemplate} {...options}>
      <Provider store={store}>
        <FirmProfileContext.Provider value={{ detailedView, setDetailedView }}>
          <Router>
            <AnimatePresence>
              <Switch>
                <Route
                  exact
                  path="/recommendation/reason"
                  component={ExplainCase}
                />

                <Route
                  exact
                  path="/recommendation/criteria/"
                  component={SearchCriteria}
                />

                <Route
                  exact
                  path="/recommendation/urgency/"
                  component={SearchUrgency}
                />

                <Route
                  exact
                  path="/recommendation/availability/"
                  component={SearchAvailability}
                />

                <Route
                  exact
                  path="/recommendation/income"
                  component={SearchIncome}
                />

                <Route
                  exact
                  path="/recommendation/location"
                  component={Location}
                />

                {/* <Route exact path="/login" component={Login} /> */}
                {/* <Route
                exact
                path="/no-premium-login"
                component={NoPremiumLogin}
              />

              <Route exact path="/login" component={Login} /> */}

                {/* <Route
                exact
                path="/upgrade_version"
                component={UpgradeClientVersion}
              /> */}

                <Route exact path="/search/fail" component={SearchFail} />

                <Route exact path="/signup/:shortId" component={SignUp} />
                {/* <Route
                exact
                path="/reset-password"
                component={PasswordResetRequest}
              /> */}

                <Route
                  exact
                  path="/reset-password/:shortId"
                  component={ResetPassword}
                />

                <Route
                  exact
                  path="/search/:coordinates/:city"
                  component={Status}
                />

                <Route
                  exact
                  path="/search/:coordinates/:city/:status"
                  component={Lawyer}
                />
                {/* <Route exact path="/search/:city/:paralegalStatus" component={Paralegal} /> */}
                <Route
                  exact
                  path="/search/:coordinates/:city/:status/:field"
                  component={Gender}
                />
                <Route
                  exact
                  path="/search/:coordinates/:city/:status/:field/:gender"
                  component={Type}
                />

                <Route exact path="/search_firm" component={LawFirm} />
                <Route
                  exact
                  path="/search/:coordinates/:city/:status/:field/:gender/:type"
                  component={Results}
                />

                {/* <Route exact path="/profile/:handle" component={Profile} /> */}
                <Route
                  exact
                  path="/profile/employee/:handle"
                  component={FirmEmpLawProfile}
                />

                <Redirect to={"recommendation/reason"} />
                <PrivateRoute path="/" component={Main} />
              </Switch>
            </AnimatePresence>
          </Router>{" "}
        </FirmProfileContext.Provider>
      </Provider>
    </AlertProvider>
  );
};

export default App;
