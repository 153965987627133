import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import { loginUser } from "../../../actions/authActions";
import TextField from "../../reusable/text_field/TextField";
import logo from "../images/logo.svg";
import http from "../../../utils/http";
import environment from "../../../utils/environment";
import { withAlert } from "react-alert";
import "../style/login.scss";

import * as Icon from "react-feather";
import "./NoPremiumLogin.scss";
import ReactGA from "react-ga";
import axios from "axios";

class NoPremiumLogin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      errors: {},
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    const { auth, history } = this.props;
    if (auth.isAuthenticated) {
      history.push("/subscription");
    }
  }

  componentWillReceiveProps(nextProps) {
    const { history } = this.props;
    if (nextProps.auth.isAuthenticated) {
      history.push("/subscription");
    }

    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }
  }

  async onSubmit(e) {
    e.preventDefault();
    const { email, password } = this.state;
    const userData = { email, password };

    if (
      !userData.email ||
      userData.email.length === 0 ||
      !userData.password ||
      userData.password.length === 0
    ) {
      this.props.alert.error("Missing Inputs!");
      return console.error("missing message");
    }

    try {
      await http.post(`${environment.resolveApi().rest}/user/login`, userData);

      // axios
      //   .post("http://localhost:5001/api/user/login", userData, {
      //     withCredentials: true,
      //   })
      //   .then((response) => {})
      //   .catch((error) => {
      //     console.log(error);
      //   });

      // await http.get(`${environment.resolveApi().rest}/user/isLoggedIn`);

      window.location.reload();
    } catch (err) {
      this.props.alert.error("Email or Password does not exist!");
    }
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  render() {
    const { errors, email, password } = this.state;

    // TODO: Pull errors.login error up since we don't need it repeated
    return (
      <div className="NoPremiumLogin">
        <div className="noPremiumLogin__content">
          <span className="noPremiumLogin__content__title">
            Oh, no it looks like you’re not a CounselConnect Premium member.
          </span>
          <span className="noPremiumLogin__content__subtitle">
            Signup for CounselConnect Premium or Lite and gain access to your
            client connections!
          </span>

          <div
            className="noPremiumLogin__content__pricing subscription__noPremiumLogin__content__pricing--premium"
            style={{ height: "fit-content", width: "100%" }}
          >
            <div className="noPremiumLogin__content__pricing__header">
              <span className="noPremiumLogin__content__pricing__header__txt">
                CC Premium (14 days free)
              </span>
              <div className="flex-column">
                <span className="noPremiumLogin__content__pricing__header__price">
                  $59
                </span>
                <small className="noPremiumLogin__content__pricing__header__subtxt">
                  monthly
                </small>
              </div>
            </div>

            <span className="noPremiumLogin__content__pricing__content__headerTxt">
              Plan includes:
            </span>

            <div className="noPremiumLogin__content__pricing__content--dashboard">
              <div className="noPremiumLogin__content__pricing__content__item">
                <Icon.Check color="#FFF" className="featherIcon__check" />
                <span className="noPremiumLogin__content__pricing__content__txt">
                  Unlimited client connections
                </span>
              </div>
              <div className="noPremiumLogin__content__pricing__content__item">
                <Icon.Check color="#FFF" className="featherIcon__check" />
                <span className="noPremiumLogin__content__pricing__content__txt">
                  Search result priority over free users
                </span>
              </div>

              <div className="noPremiumLogin__content__pricing__content__item">
                <Icon.Check color="#FFF" className="featherIcon__check" />
                <span className="noPremiumLogin__content__pricing__content__txt">
                  Gold styled profile cards
                </span>
              </div>

              <div className="noPremiumLogin__content__pricing__content__item">
                <Icon.Check color="#FFF" className="featherIcon__check" />
                <span className="noPremiumLogin__content__pricing__content__txt">
                  Full Detailed Profile
                </span>
              </div>

              <div className="noPremiumLogin__content__pricing__content__item">
                <Icon.Check color="#FFF" className="featherIcon__check" />
                <span className="noPremiumLogin__content__pricing__content__txt">
                  Unlimited Pages Views
                </span>
              </div>
            </div>
          </div>

          <div className="noPremiumLogin__content__pricing subscription__noPremiumLogin__content__pricing--lite">
            <div className="noPremiumLogin__content__pricing__header">
              <span className="noPremiumLogin__content__pricing__header__txt ">
                CC Lite (14 days free)
              </span>
              <div className="flex-column">
                <span className="noPremiumLogin__content__pricing__header__price ">
                  $25
                </span>
                <small className="noPremiumLogin__content__pricing__header__subtxt ">
                  monthly
                </small>
              </div>
            </div>

            <span className="noPremiumLogin__content__pricing__content__headerTxt ">
              Plan includes:
            </span>

            <div className=" noPremiumLogin__content__pricing__content--dashboard">
              <div className="noPremiumLogin__content__pricing__content__item">
                <Icon.Check color="#FFF" className="featherIcon__check" />
                <span className="noPremiumLogin__content__pricing__content__txt ">
                  5 client connections (pr year)
                </span>
              </div>
              <div className="noPremiumLogin__content__pricing__content__item">
                <Icon.Check color="#FFF" className="featherIcon__check" />
                <span className="noPremiumLogin__content__pricing__content__txt ">
                  Full Detailed Profile
                </span>
              </div>

              <div className="noPremiumLogin__content__pricing__content__item">
                <Icon.Check color="#FFF" className="featherIcon__check" />
                <span className="noPremiumLogin__content__pricing__content__txt ">
                  Unlimited Pages Views
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="Login">
          <img className="side-navbar-home-icon" src={logo} alt="" />

          <h1 className="login-title">Log in to CounselConnect</h1>
          <p className="login-subtitle">Sole Practitioner Login</p>

          {/* <p className="login-subtitle">Please log into your CounselConnect account</p> */}

          <form onSubmit={this.onSubmit} className="login-form-container">
            <TextField
              className="login-portal-input"
              title="Email"
              placeholder=""
              name="email"
              type="email"
              value={email}
              onChange={this.onChange}
              error={errors.login}
            />
            <div className="password-input-container">
              <TextField
                className="login-portal-input"
                title="Password"
                placeholder=""
                name="password"
                type="password"
                value={password}
                onChange={this.onChange}
                error={errors.login}
              />
              <Link to="/reset-password" className="forgot-password-link">
                Forgot Password?
              </Link>
            </div>

            <button type="submit" className="login-button">
              LOGIN
            </button>
          </form>

          <div className="login-bottom-box">
            <span className="login-bottom-box-txt">Don't have an account?</span>
            <a
              href="https://counselconnect630.typeform.com/to/d2d7fO"
              className="login-bottom-box-link"
            >
              Sign Up
            </a>
          </div>

          <p className="copyright text-muted">
            Copyright &copy; 2023 by CounselConnect Inc.
          </p>
        </div>
      </div>
    );
  }
}

NoPremiumLogin.propTypes = {
  loginUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps, { loginUser })(
  withAlert()(NoPremiumLogin)
);
