import React, { useState, useEffect, lazy, Suspense } from "react";
import http from "../../../utils/http";
import environment from "../../../utils/environment";
// import EmployeeProfileItem from '../law_profile_cards/EmployeeProfileItem';
import "./location.scss";
import "./law_firm_results/lawFirmResults.scss";
import FirmsReviewsSection from "../law_public_profile/FirmsReviewsSection";
import "./LawFirmResult.scss";
import StarRatings from "react-star-ratings";

const EmployeeProfileItem = lazy(() =>
  import("../law_profile_cards/EmployeeProfileItem")
);

const LawFirm = (props) => {
  console.log(props);

  if (!props.location.state) {
    props.history.push({ pathname: `/recommendation/reason` });
  } else {
    var { lawFirmId, lawFirmEmployeeHandles, lawTitle, lawServices } =
      props.location.state;
  }

  const [lawFirmInfo, setFirmInfo] = useState([]);
  const [employees, setEmployeeInfo] = useState([]);

  const getAvgRating = (listOfReviews) => {
    let average = 0;

    // loop through the list of reviews and add up the total rating values
    if (listOfReviews.length > 0) {
      for (let i = 0; i < listOfReviews.length; i++) {
        average += parseInt(listOfReviews[i].rating);
      }
      average = average / listOfReviews.length;
    }

    return average.toFixed(1);
  };

  useEffect(() => {
    const getLawFirmInfo = async () => {
      const response = await http.get(
        `${environment.resolveApi().rest}/profile/law_firm_reviews/${lawFirmId}`
      );

      console.log("response.data.law_firm", response.data.law_firm);
      setFirmInfo(response.data.law_firm);
    };

    const getEmployees = async () => {
      let arr = [];

      for (let i = 0; i < lawFirmEmployeeHandles.length; i++) {
        const response = await http.get(
          `${environment.resolveApi().rest}/profile/law_firm/employee/${
            lawFirmEmployeeHandles[i]
          }`
        );
        // console.log(response.data.profile);
        arr.push(response.data.profile);
      }
      setEmployeeInfo(arr);
    };
    getLawFirmInfo();
    getEmployees();
  }, [lawFirmEmployeeHandles, lawFirmId]);

  return (
    <div className="" style={{ height: "100%" }}>
      <div className="lawyer-search-social-profile-mobile-navbar">
        <a href="https://counselconnect.ca/">
          <img
            className="lawyer-search-social-profile-mobile-navbar-img"
            src={require("../law_search/images/cc-single-logo.svg")}
            alt="counsel-connect-log"
          />
        </a>
      </div>

      <div className="search-header mobile-hide table-view">
        <a href="https://counselconnect.ca/">
          <img
            className="social-profile-nav-cc-logo"
            src={require("../law_search/images/cc-search-logo.svg")}
            alt="counsel-connect-log"
          />
        </a>
      </div>

      <div className="firm-employees-pg-firm-info-wrapper">
        {" "}
        {/* TITLE ---- ---- ---- ---- ---- ---- ---- ---- ---- ---- ---- */}
        <h5 className="social-profile-txt-dark number-of-resulted-firms">
          We found you {lawFirmEmployeeHandles.length}{" "}
          <strong>
            {lawFirmEmployeeHandles.length > 1 ? `${lawTitle}s` : lawTitle}
          </strong>{" "}
          for <strong>{lawServices}</strong> at{" "}
          <strong>{lawFirmInfo.name}</strong>
        </h5>
      </div>

      <div className="firmContainer">
        <div className="firm-page-wrapper">
          {employees.length > 0
            ? employees.map((employee, index) => (
                <Suspense
                  fallback={
                    <div className="fallback-firm-card-container">
                      Loading...
                    </div>
                  }
                >
                  <EmployeeProfileItem
                    key={index}
                    profile={employee}
                    lawFirmInfo={lawFirmInfo}
                    cardOrder={index}
                  />
                </Suspense>
              ))
            : ""}
        </div>

        <div className="lawFirmResult__reviewsWrap">
          <div className="lawFirmResult__reviews__logoWrap">
            {lawFirmInfo.companyLogo && (
              <img
                className="lawFirmResult__logo"
                src={lawFirmInfo.companyLogo}
              />
            )}
            <span className="lawFirmResult__name">{lawFirmInfo.name}</span>
          </div>
          <div className="lawFirmResult__bestReview">
            {lawFirmInfo.best_review && lawFirmInfo.reviews_avg_rating ? (
              <div className="">
                <div className="">
                  <hr
                    style={{
                      width: "100%",
                      border: "1px solid #F3F1F3",
                      marginBottom: "30px",
                    }}
                  />

                  <div className="social-profile-best-rating-number-wrapper">
                    <div className="social-profile-best-rating-star-wrapper">
                      <h1
                        style={{ display: "flex" }}
                        className="social-profile-txt-dark social-profile-txt-bold"
                      >
                        {lawFirmInfo.reviews_avg_rating
                          ? lawFirmInfo.reviews_avg_rating
                          : getAvgRating(lawFirmInfo.reviews)}
                        <h5
                          className="social-profile-txt-light"
                          style={{ marginTop: "15px" }}
                        >
                          /5
                        </h5>
                      </h1>
                      <div className="rating-container best-review-stars-wrapper">
                        <StarRatings
                          className="rating-container"
                          rating={parseInt(lawFirmInfo.reviews_avg_rating)}
                          starRatedColor="#3AC18D"
                          // changeRating={()=>changeRating(2)}
                          numberOfStars={5}
                          name="rating"
                          starDimension="26px"
                          starSpacing="2px"
                        />
                      </div>
                      <p
                        style={{ marginTop: "10px" }}
                        className="social-profile-txt-light"
                      >{`${lawFirmInfo.reviews.length} Reviews`}</p>
                    </div>

                    <div className="social-profile-most-helpful-review-text-container">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="28"
                        height="23"
                        viewBox="0 0 26 23"
                      >
                        <path
                          fill="#D0CDD0"
                          fill-rule="evenodd"
                          d="M0 15.025c0-2.4.363-4.57 1.088-6.508.726-1.94 1.667-3.488 2.825-4.646 1.158-1.157 2.372-2.056 3.64-2.698C8.824.53 10.1.14 11.384 0v4.06c-1.813.39-3.32 1.41-4.52 3.055-1.2 1.646-1.827 3.39-1.883 5.232.42-.28.99-.42 1.716-.42 1.48 0 2.707.475 3.683 1.424.976.95 1.464 2.22 1.464 3.81 0 1.423-.537 2.636-1.61 3.64-1.075 1.005-2.408 1.507-3.998 1.507-1.786 0-3.27-.627-4.457-1.883C.592 19.17 0 17.37 0 15.024zm14 0c0-2.4.363-4.57 1.088-6.508.726-1.94 1.674-3.488 2.846-4.646 1.172-1.157 2.393-2.056 3.662-2.698C22.866.53 24.142.14 25.426 0v4.06c-1.814.39-3.328 1.41-4.54 3.055-1.215 1.646-1.85 3.39-1.906 5.232.42-.28.99-.42 1.716-.42 1.48 0 2.707.475 3.683 1.424.976.95 1.464 2.22 1.464 3.81 0 1.423-.53 2.636-1.59 3.64-1.06 1.005-2.386 1.507-3.976 1.507-1.786 0-3.28-.627-4.478-1.883-1.2-1.255-1.8-3.055-1.8-5.4z"
                        ></path>
                      </svg>
                      <div className="best-review-content-wrapper">
                        <strong className="social-profile-txt-salmon">
                          MOST HELPFUL REVIEW
                        </strong>
                        <p className="social-profile-txt-light best-review-content">
                          {lawFirmInfo.best_review.review}
                        </p>
                      </div>
                      <svg
                        className="review-svg-quote-2"
                        xmlns="http://www.w3.org/2000/svg"
                        width="28"
                        height="23"
                        viewBox="0 0 28 23"
                      >
                        <path
                          fill="#D0CDD0"
                          fill-rule="evenodd"
                          d="M0 5.148c0-1.423.53-2.637 1.59-3.64C2.65.5 3.976 0 5.566 0c1.786 0 3.28.628 4.48 1.883 1.198 1.256 1.798 3.056 1.798 5.4 0 2.4-.362 4.56-1.088 6.487-.725 1.925-1.674 3.452-2.846 4.582-1.172 1.13-2.385 2.023-3.64 2.68-1.257.655-2.54 1.066-3.85 1.234v-4.018c1.84-.42 3.36-1.437 4.56-3.055 1.2-1.62 1.828-3.377 1.884-5.274-.42.278-.99.418-1.716.418-1.48 0-2.707-.468-3.683-1.402C.488 8 0 6.738 0 5.148zm16 0c0-1.423.53-2.637 1.59-3.64C18.65.5 19.976 0 21.566 0c1.786 0 3.28.628 4.48 1.883 1.198 1.256 1.798 3.056 1.798 5.4 0 2.4-.362 4.56-1.088 6.487-.725 1.925-1.674 3.452-2.846 4.582-1.172 1.13-2.385 2.023-3.64 2.68-1.257.655-2.54 1.066-3.85 1.234v-4.018c1.84-.42 3.36-1.437 4.56-3.055 1.2-1.62 1.828-3.377 1.884-5.274-.42.278-.99.418-1.716.418-1.48 0-2.707-.468-3.683-1.402C16.488 8 16 6.738 16 5.148z"
                        ></path>
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}{" "}
          </div>

          {lawFirmInfo.reviews && lawFirmInfo.reviews.length > 0 ? (
            <FirmsReviewsSection profileId={lawFirmInfo._id} />
          ) : (
            <div className="reviews-container">
              <div className="social-profile-reviews-header-wrap">
                <div className="valid-reviews-container">
                  <h5 className="social-profile-txt-dark">
                    <strong>0 Detailed Reviews</strong>
                  </h5>

                  <small className="social-profile-txt-light">
                    All reviews are the subjective opinions of a client and not
                    of CounselConnect.
                  </small>
                </div>
                <div className="social-profile-line-wrap">
                  {/* <img src={require("./images/oval.svg")} alt="oval" /> */}
                  <hr />
                </div>
              </div>

              <div className="no-reviews-container">
                <p className="social-profile-txt-light">
                  This practitioner hasn't received any reviews from clients
                  yet!
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default LawFirm;
