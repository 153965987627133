import React, { useState, useEffect, lazy, Suspense } from "react";
import { Link } from "react-router-dom";
import http from "../../../utils/http";
import "./profileitem.scss";
import "../law_search/results.scss";
import RatingStar from "../law_public_profile/images/star.svg";
import trustedIcon from "../law_search/images/trust-badge.svg";
import environment from "../../../utils/environment";
import * as Icon from "react-feather";
import "./profileCardImage.scss";
import { withRouter } from "react-router-dom";

const ProfileCardImage = lazy(() => import("./ProfileCardImage.jsx"));

const ProfileItem = (props) => {
  let [userHasPrem, setUserHasPrem] = useState(false);
  const { profile, user_id, profileInfo, distance, duration } = props;
  // console.log(profileInfo);

  const handle = profileInfo.handle;
  // const lawTitle = `${profileInfo.status}`

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [numOfPageViews, setNumOfPageViews] = useState("");

  const getPageView = async () => {
    try {
      const response = await http.post(
        `${environment.resolveApi().rest}/profile/analytics/numOfPageViews`,
        { profileId: profileInfo._id }
      );
      setNumOfPageViews(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getIsUserPremium = async () => {
    try {
      let response = await http.post(
        `${environment.resolveApi().rest}/user/is-user-premium`,
        { profileId: profileInfo._id }
      );

      console.log(response);

      // if true
      if (response.data.success) {
        setUserHasPrem(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const getUserInfoFromHandle = async () => {
      try {
        const response = await http.get(
          `${environment.resolveApi().rest}/profile/${handle}/info`
        );
        setFirstName(response.data.firstName);
        setLastName(response.data.lastName);

        await getIsUserPremium();
      } catch (error) {
        // console.log(error);
      }
    };

    getUserInfoFromHandle();
    getPageView();

    // fetchLawFirmInfo(profile.lawFirmName);
  }, [handle]);

  const getAvgRating = (listOfReviews) => {
    let average = 0;

    // loop through the list of reviews and add up the total rating values
    if (listOfReviews.length > 0) {
      for (let i = 0; i < listOfReviews.length; i++) {
        average += parseInt(listOfReviews[i].rating);
      }
      average = average / listOfReviews.length;
    }

    return average.toFixed(1);
  };

  const newPageView = async () => {
    try {
      await http.post(
        `${environment.resolveApi().rest}/profile/analytics/pageView`,
        { profileId: profileInfo._id }
      );
    } catch (error) {
      console.log(error);
    }
  };

  const onSubmitFields = () => {
    props.history.push({ pathname: `/profile/${handle}` });
  };

  const onViewProfile = () => {
    newPageView();
    onSubmitFields();
  };

  return (
    <div
      className={userHasPrem ? "profile-card-p--ccPremium" : "profile-card-p"}
      onClick={onViewProfile}
    >
      {/* <Link to={{pathname: `/profile/${handle}`}} style={{textDecoration: 'none'}}> */}
      <div
        className={
          userHasPrem
            ? "profile-card-order-number--ccPremium mobile-hide"
            : "profile-card-order-number mobile-hide"
        }
      >
        {props.cardOrder + 1}
      </div>

      <div className={userHasPrem ? "card-p--ccPremium" : "card-p"}>
        <div
          className={
            userHasPrem
              ? "profile-card-img-n-order-wrapper--ccPremium"
              : "profile-card-img-n-order-wrapper"
          }
        >
          <div
            className={
              userHasPrem
                ? "profile-card-order-number--ccPremium"
                : "profile-card-order-number"
            }
          >
            {props.cardOrder + 1}
          </div>

          <div
            className={
              userHasPrem
                ? "profile-card-img-wrapper--ccPremium"
                : "profile-card-img-wrapper"
            }
          >
            <img
              className="rounded-circle profile-card-img"
              src={`${profileInfo.pictureURL}`}
              alt=""
            />
          </div>
        </div>

        <div className={userHasPrem ? "card-info--ccPremium" : "card-info"}>
          <div className="profile-card-title">
            <div className="profile-card-header-container">
              <div className="profile-card-header-txt-conainer">
                <div className="emp-card-cc-trusted-wrap mobile-hide">
                  <img
                    style={{
                      width: "20px",
                      marginRight: "10px",
                      marginBottom: "10px",
                    }}
                    src={require(userHasPrem
                      ? "../law_public_profile/images/cc-prem-heart.svg"
                      : "../law_public_profile/images/trustHeart.svg")}
                    alt=""
                  />
                  <strong
                    className={
                      userHasPrem
                        ? "emp-card-cc-trusted-txt social-profile-txt-yellow mobile-hide"
                        : "emp-card-cc-trusted-txt social-profile-txt-red mobile-hide"
                    }
                  >
                    COUNSELCONNECT TRUSTED
                  </strong>
                </div>

                <h4 className="social-profile-txt-dark firm-card-name">{`${firstName} ${lastName}`}</h4>

                {/* <div className="profile-card-information-container">
                      <i className="fas fa-language social-profile-txt-light" style={{ marginRight: '.5em' }} />
                      <p className="social-profile-txt-light">{profileInfo.languages}</p>
                </div> */}

                <div className="profile-card-information-container">
                  {profileInfo.num_of_reviews &&
                  profileInfo.num_of_reviews > 0 ? (
                    <div className="firm-card-rating-container">
                      <div
                        className="firm-card-rating-container"
                        style={{ marginRight: "10px" }}
                      >
                        <img
                          className=" profile-card-rating-start-img"
                          src={require("../law_search/images/greenStar.svg")}
                        />
                        <strong className="social-profile-txt-dark firm-card-avg-rating">
                          {profileInfo.reviews_avg_rating}{" "}
                          <span
                            className="social-profile-txt-light"
                            style={{ fontWeight: "100" }}
                          >
                            ({profileInfo.num_of_reviews})
                          </span>
                        </strong>
                      </div>

                      <div className="firm-card-rating-container">
                        <img
                          className=" profile-card-rating-start-img"
                          src={require("../law_search/images/pin.svg")}
                        />
                        <span
                          className="social-profile-txt-light firm-card-address"
                          style={{ marginRight: "10px" }}
                        >
                          {" "}
                          {distance.text}
                        </span>
                      </div>

                      <div className="firm-card-rating-container">
                        <img
                          className=" profile-card-rating-start-img"
                          src={require("../law_search/images/car.svg")}
                        />
                        <span
                          className="social-profile-txt-light firm-card-address"
                          style={{ marginRight: "10px" }}
                        >
                          {" "}
                          {duration.text}
                        </span>
                      </div>

                      {profileInfo.consultation_fee == "FREE" ? (
                        <div className="">
                          <Icon.PhoneCall
                            className="phone-icon social-profile-txt-light"
                            size={18}
                            strokeWidth={2.5}
                          />
                          <span
                            className="social-profile-txt-light firm-card-fee-txt"
                            style={{ marginLeft: "7px" }}
                          >
                            {profileInfo.consultation_fee}
                          </span>
                        </div>
                      ) : (
                        <div className="">
                          <Icon.PhoneCall
                            className="phone-icon social-profile-txt-light"
                            size={18}
                            strokeWidth={2.5}
                          />
                          <span
                            className="social-profile-txt-light firm-card-fee-txt"
                            style={{ marginLeft: "7px" }}
                          >
                            $$
                          </span>
                        </div>
                      )}
                    </div>
                  ) : (
                    <div className="firm-card-rating-container">
                      <div className="firm-card-rating-container">
                        <img
                          className=" profile-card-rating-start-img"
                          src={require("../law_search/images/greenStar.svg")}
                          style={{ marginRight: "10px" }}
                        />
                        <strong className="social-profile-txt-dark firm-card-avg-rating">
                          0{" "}
                          <span
                            className="social-profile-txt-light"
                            style={{ fontWeight: "100" }}
                          >
                            (0)
                          </span>
                        </strong>
                      </div>
                      {/* <span className="social-profile-txt-light firm-card-address" style={{marginRight: '10px'}}> ∙ {profileInfo.languages}</span> */}

                      <div className="firm-card-rating-container">
                        <img
                          className=" profile-card-rating-start-img"
                          src={require("../law_search/images/pin.svg")}
                        />
                        <span
                          className="social-profile-txt-light firm-card-address"
                          style={{ marginRight: "10px" }}
                        >
                          {" "}
                          {distance.text}
                        </span>
                      </div>

                      <div className="firm-card-rating-container">
                        <img
                          className=" profile-card-rating-start-img"
                          src={require("../law_search/images/car.svg")}
                        />
                        <span
                          className="social-profile-txt-light firm-card-address"
                          style={{ marginRight: "10px" }}
                        >
                          {" "}
                          {duration.text}
                        </span>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <Suspense
                fallback={
                  <div className="fallback-profile-card-img-container rounded-circle profile-card-img mobile-hide">
                    <Icon.User
                      className="fallback-profile-card-img-usr-icon"
                      strokeWidth={2}
                      color={"#3F2223"}
                    />
                  </div>
                }
              >
                <ProfileCardImage
                  pictureURL={profileInfo.pictureURL}
                  className={"rounded-circle profile-card-img mobile-hide"}
                  {...props}
                />
              </Suspense>
            </div>

            <div className="desktop-hide">
              {numOfPageViews > 0 && (
                <div>
                  <div className="card-dotted-line"></div>

                  <div
                    className="firm-card-consultation-wrapper"
                    style={{ marginTop: "5px" }}
                  >
                    <svg
                      viewBox="64 64 896 896"
                      focusable="false"
                      class=""
                      data-icon="rise"
                      width="1.3em"
                      height="1.3em"
                      fill="#FC6970"
                      aria-hidden="true"
                    >
                      <path d="M917 211.1l-199.2 24c-6.6.8-9.4 8.9-4.7 13.6l59.3 59.3-226 226-101.8-101.7c-6.3-6.3-16.4-6.2-22.6 0L100.3 754.1a8.03 8.03 0 0 0 0 11.3l45 45.2c3.1 3.1 8.2 3.1 11.3 0L433.3 534 535 635.7c6.3 6.2 16.4 6.2 22.6 0L829 364.5l59.3 59.3a8.01 8.01 0 0 0 13.6-4.7l24-199.2c.7-5.1-3.7-9.5-8.9-8.8z"></path>
                    </svg>

                    <span
                      className="social-profile-txt-light firm-card-consultation-txt"
                      style={{ color: "#595555" }}
                    >
                      {" "}
                      Viewed by {numOfPageViews}{" "}
                      {numOfPageViews == 1 ? "person" : "people"} in the past
                      week{" "}
                    </span>
                  </div>
                </div>
              )}
            </div>

            <div
              className={
                numOfPageViews > 0
                  ? userHasPrem
                    ? "profile-card-button-bar-conatiner--ccPremium"
                    : "profile-card-button-bar-conatiner"
                  : userHasPrem
                  ? "profile-card-button-bar-conatiner--ccPremium"
                  : "profile-card-button-bar-conatiner profile-card-bar-no-pg-rev"
              }
            >
              {numOfPageViews > 0 && (
                <strong className="profile-card-button-bar-analytics-txt">
                  <svg
                    style={{ marginRight: "10px" }}
                    viewBox="64 64 896 896"
                    focusable="false"
                    class=""
                    data-icon="rise"
                    width="1.4em"
                    height="1.4em"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path d="M917 211.1l-199.2 24c-6.6.8-9.4 8.9-4.7 13.6l59.3 59.3-226 226-101.8-101.7c-6.3-6.3-16.4-6.2-22.6 0L100.3 754.1a8.03 8.03 0 0 0 0 11.3l45 45.2c3.1 3.1 8.2 3.1 11.3 0L433.3 534 535 635.7c6.3 6.2 16.4 6.2 22.6 0L829 364.5l59.3 59.3a8.01 8.01 0 0 0 13.6-4.7l24-199.2c.7-5.1-3.7-9.5-8.9-8.8z"></path>
                  </svg>
                  Viewed by {numOfPageViews}{" "}
                  {numOfPageViews == 1 ? "person" : "people"} in the past week
                </strong>
              )}

              <button
                className={
                  userHasPrem
                    ? "profile-view-profile-btn--ccPremium"
                    : "profile-view-profile-btn"
                }
              >
                View full profile
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* </Link> */}
    </div>
  );
};

export default withRouter(ProfileItem);
