import React, { useState, useEffect, useCallback, useRef } from "react";

import { withAlert } from "react-alert";
import "../search_criteria/SearchCriteria.scss";
import http from "../../../../utils/http";
import * as Icon from "react-feather";

import dateFormat from "dateformat";
import { motion } from "framer-motion";

import "./SearchUrgency.scss";

const SearchUrgency = (props) => {
  const [inputValue, setInputValue] = useState("  ");
  const [loading, setLoading] = useState(false);
  const [selectedDiv, setSelectedDiv] = useState("");
  const topOfContainer = useRef(null);

  const scrollToTop = () => {
    let options = { behaviour: "smooth", block: "center", inline: "start" };

    console.log({ topOfContainer });

    // we want the React lifecycle to complete before scrolling
    setTimeout(() => {
      if (topOfContainer) {
        console.log("testing");

        topOfContainer.current.scrollIntoView(options);
      }
    }, 0);
  };

  useEffect(() => {
    scrollToTop();
  }, []); // Empty dependency array ensures the effect only runs once on mount

  // Function to handle div selection
  const handleDivClick = (divId) => {
    setSelectedDiv(divId);

    console.log("divId", divId);

    const local_search = localStorage.getItem("search_save");
    const local_search_parsed = JSON.parse(local_search);
    // save to localstorage
    const dataToSave = {
      date: dateFormat(new Date(), "mmmm dd"),
      pathname: "recommendation/criteria",
      service_request: local_search_parsed.service_request,
      message: local_search_parsed.message,
      aiResponse: local_search_parsed.aiResponse,
      criteria: local_search_parsed.criteria,
      urgency: divId,
      availability: [],
      city: null,
      status: "pending",
      income: null,
    };
    localStorage.setItem("search_save", JSON.stringify(dataToSave));
    props.history.push({
      pathname: `/recommendation/availability`,
    });
  };

  const onSubmit = (urgency) => {
    // const local_search = localStorage.getItem("search_save");
    // const local_search_parsed = JSON.parse(local_search);
    // // save to localstorage
    // const dataToSave = {
    //   date: dateFormat(new Date(), "mmmm dd"),
    //   pathname: "recommendation/criteria",
    //   service_request: local_search_parsed.service_request,
    //   message: local_search_parsed.message,
    //   aiResponse: local_search_parsed.aiResponse,
    //   criteria: local_search_parsed.criteria,
    //   urgency: urgency,
    //   availability: [],
    //   city: null,
    //   status: "pending",
    // };
    // localStorage.setItem("search_save", JSON.stringify(dataToSave));
    // props.history.push({
    //   pathname: `/recommendation/availability`,
    // });
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      style={{ backgroundColor: "#FFF" }}
    >
      <div>
        <div
          className=""
          ref={topOfContainer}
          style={{ width: 100, height: 10 }}
        ></div>
        <div className="ccSearch__header">
          <a href="https://counselconnect.ca/">
            <img
              className="lawyer-search-navbar-logo"
              src={require("../images/cc-search-logo--blue.svg")}
              alt="counsel-connect-log"
            />
          </a>
        </div>

        <div className=" searchCriteria__body">
          <div className="explainCase__content__form">
            <h4 className="explainCase__content__title">
              How urgent is it for you to see a Lawyer?
            </h4>

            <span className="explainCase__content__subtitle">
              Select the one that applies to you most
            </span>

            <div className="common__buttonContainer">
              <div
                onClick={() => {
                  handleDivClick("ASAP");
                  onSubmit();
                }}
                className={
                  selectedDiv === "ASAP"
                    ? "common__selectButton--active common__selectButton common__singleSelectBtn searchUrgency__selectButton"
                    : "common__selectButton common__singleSelectBtn searchUrgency__selectButton"
                }
              >
                <span className="common__selectButton__txt">ASAP</span>
                <span className="common__selectButton__subtxt">
                  As soon as possible
                </span>
              </div>

              <div
                onClick={() => {
                  handleDivClick("Very Urgent");
                  onSubmit();
                }}
                className={
                  selectedDiv === "Very Urgent"
                    ? "common__selectButton--active common__selectButton common__selectButton--consecutive common__singleSelectBtn searchUrgency__selectButton"
                    : "common__selectButton common__selectButton--consecutive common__singleSelectBtn searchUrgency__selectButton"
                }
              >
                <span className="common__selectButton__txt">Very Urgent</span>
                <span className="common__selectButton__subtxt">
                  Within 2-3 days
                </span>
              </div>

              <div
                onClick={() => {
                  handleDivClick("Kinda Urgent");
                  onSubmit();
                }}
                className={
                  selectedDiv === "Kinda Urgent"
                    ? "common__selectButton--active common__selectButton common__selectButton--consecutive common__singleSelectBtn searchUrgency__selectButton"
                    : "common__selectButton common__selectButton--consecutive common__singleSelectBtn searchUrgency__selectButton"
                }
              >
                <span className="common__selectButton__txt">Kinda Urgent</span>
                <span className="common__selectButton__subtxt">
                  Within 1-2 week
                </span>
              </div>

              <div
                onClick={() => {
                  handleDivClick("Not Urgent");
                  onSubmit();
                }}
                className={
                  selectedDiv === "Not Urgent"
                    ? "common__selectButton--active common__selectButton common__selectButton--row2 common__singleSelectBtn searchUrgency__selectButton"
                    : "common__selectButton common__selectButton--row2 common__singleSelectBtn searchUrgency__selectButton"
                }
              >
                <span className="common__selectButton__txt">Not Urgent</span>
                <span className="common__selectButton__subtxt">2 weeks+</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default SearchUrgency;
