import React, { useState, useEffect, useCallback, useRef } from "react";

import { withAlert } from "react-alert";
import "./SearchCriteria.scss";
import http from "../../../../utils/http";
import * as Icon from "react-feather";
import dateFormat from "dateformat";

import { motion } from "framer-motion";

const SearchCriteria = (props) => {
  const [inputValue, setInputValue] = useState("  ");
  const [loading, setLoading] = useState(false);
  const [activeDivs, setActiveDivs] = useState({});
  const topOfContainer = useRef(null);
  const [btnDisabled, setBtnDisabled] = useState(true);

  // Function to toggle the active state of a div
  const toggleActive = (divId) => {
    setActiveDivs((prevActiveDivs) => ({
      ...prevActiveDivs,
      [divId]: !prevActiveDivs[divId],
    }));
  };

  const isEmptyObject = (obj) => {
    return Object.keys(obj).length === 0;
  };

  const onSubmit = () => {
    console.log("activeDivs", activeDivs);
    if (isEmptyObject(activeDivs)) {
      props.alert.error("Please select atleast one option.");
      return;
    }

    const local_search = localStorage.getItem("search_save");
    const local_search_parsed = JSON.parse(local_search);

    // save to localstorage
    const dataToSave = {
      date: dateFormat(new Date(), "mmmm dd"),
      pathname: "recommendation/criteria",
      city: null,
      status: "pending",
      service_request: local_search_parsed.service_request,
      message: local_search_parsed.message,
      aiResponse: local_search_parsed.aiResponse,
      criteria: activeDivs,
      urgency: null,
      availability: [],
      income: null,
    };

    localStorage.setItem("search_save", JSON.stringify(dataToSave));

    props.history.push({
      pathname: `/recommendation/urgency`,
    });
  };

  const scrollToTop = () => {
    let options = { behaviour: "smooth", block: "center", inline: "start" };

    console.log({ topOfContainer });

    // we want the React lifecycle to complete before scrolling
    setTimeout(() => {
      if (topOfContainer) {
        console.log("testing");

        topOfContainer.current.scrollIntoView(options);
      }
    }, 0);
  };

  function countTrueFields(obj) {
    let count = 0;

    // Iterate over the object properties
    for (let key in obj) {
      // Check if the property value is true
      if (obj.hasOwnProperty(key) && obj[key] === true) {
        count++;
      }
    }

    return count;
  }

  useEffect(() => {
    scrollToTop();
  }, []); // Empty dependency array ensures the effect only runs once on mount

  useEffect(() => {
    // if there is atleast one selection done
    if (!isEmptyObject(activeDivs)) {
      let numOfActive = countTrueFields(activeDivs);

      if (numOfActive > 0) {
        setBtnDisabled(false);
      } else {
        setBtnDisabled(true);
      }
    }
  }, [activeDivs]);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      style={{ backgroundColor: "#FFF" }}
    >
      <div>
        <div
          className=""
          ref={topOfContainer}
          style={{ width: 100, height: 10 }}
        ></div>

        <div className="ccSearch__header">
          <a href="https://counselconnect.ca/">
            <img
              className="lawyer-search-navbar-logo"
              src={require("../images/cc-search-logo--blue.svg")}
              alt="counsel-connect-log"
            />
          </a>
        </div>

        <div className=" searchCriteria__body">
          <div className="explainCase__content__form">
            <h4 className="explainCase__content__title">
              The most important factors in choosing a Lawyer are…
            </h4>

            <span className="explainCase__content__subtitle">
              Select all that apply
            </span>

            <div className="common__buttonContainer">
              <div
                onClick={() => toggleActive("Lawyer service fees")}
                className={
                  activeDivs["Lawyer service fees"]
                    ? " common__selectButton common__selectButton--active searchCritera__selectButton"
                    : "common__selectButton searchCritera__selectButton"
                }
              >
                <Icon.DollarSign color="#000" />
                <span className="common__selectButton__txt">
                  Lawyer service fees
                </span>
              </div>

              <div
                onClick={() => toggleActive("Closer location")}
                className={
                  activeDivs["Closer location"]
                    ? "common__selectButton--active common__selectButton common__selectButton--consecutive searchCritera__selectButton"
                    : "common__selectButton common__selectButton--consecutive searchCritera__selectButton"
                }
              >
                <Icon.MapPin color="#000" />
                <span className="common__selectButton__txt">
                  Closer location
                </span>
              </div>

              <div
                onClick={() => toggleActive("Free initial consultations")}
                className={
                  activeDivs["Free initial consultations"]
                    ? "common__selectButton--active common__selectButton common__selectButton--consecutive searchCritera__selectButton"
                    : "common__selectButton common__selectButton--consecutive searchCritera__selectButton"
                }
              >
                <Icon.PhoneCall color="#000" />
                <span className="common__selectButton__txt">
                  Free initial consultations
                </span>
              </div>

              <div
                onClick={() => toggleActive("Accepts legal aid")}
                className={
                  activeDivs["Accepts legal aid"]
                    ? "common__selectButton--active common__selectButton common__selectButton--row2 searchCritera__selectButton"
                    : "common__selectButton common__selectButton--row2 searchCritera__selectButton"
                }
              >
                <Icon.Heart color="#000" />
                <span className="common__selectButton__txt">
                  Accepts legal aid
                </span>
              </div>

              <div
                onClick={() => toggleActive("Languages they speak")}
                className={
                  activeDivs["Languages they speak"]
                    ? "common__selectButton--active common__selectButton common__selectButton--consecutive--row2 searchCritera__selectButton"
                    : "common__selectButton common__selectButton--consecutive--row2 searchCritera__selectButton"
                }
              >
                <Icon.Globe color="#000" />
                <span className="common__selectButton__txt">
                  Languages they speak
                </span>
              </div>

              <div
                onClick={() => toggleActive("Gender")}
                className={
                  activeDivs["Gender"]
                    ? "common__selectButton--active common__selectButton common__selectButton--consecutive--row2 searchCritera__selectButton"
                    : "common__selectButton common__selectButton--consecutive--row2 searchCritera__selectButton"
                }
              >
                <Icon.User color="#000" />
                <span className="common__selectButton__txt">Gender</span>
              </div>
            </div>

            <button
              onClick={onSubmit}
              className={`searchCriteria__content__btn ${
                btnDisabled && "disabled"
              }`}
              type="button"
              disabled={btnDisabled}
            >
              <span className="searchCriteria__content__btnTxt"> Continue</span>

              <Icon.ArrowRight color="#FFF" />
            </button>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default withAlert()(SearchCriteria);
