import React, { useState, useEffect, useCallback, useRef } from "react";

import { withAlert } from "react-alert";
import "../search_criteria/SearchCriteria.scss";
import http from "../../../../utils/http";
import * as Icon from "react-feather";
import "./SearchAvailability.scss";
import dateFormat from "dateformat";
import { motion } from "framer-motion";

const SearchAvailability = (props) => {
  const [inputValue, setInputValue] = useState("  ");
  const [loading, setLoading] = useState(false);
  const [activeDivs, setActiveDivs] = useState({});
  const topOfContainer = useRef(null);
  const [btnDisabled, setBtnDisabled] = useState(true);

  const scrollToTop = () => {
    let options = { behaviour: "smooth", block: "center", inline: "start" };

    console.log({ topOfContainer });

    // we want the React lifecycle to complete before scrolling
    setTimeout(() => {
      if (topOfContainer) {
        console.log("testing");

        topOfContainer.current.scrollIntoView(options);
      }
    }, 0);
  };

  useEffect(() => {
    scrollToTop();
  }, []); // Empty dependency array ensures the effect only runs once on mount

  // Function to toggle the active state of a div
  const toggleActive = (divId) => {
    setActiveDivs((prevActiveDivs) => ({
      ...prevActiveDivs,
      [divId]: !prevActiveDivs[divId],
    }));
  };

  const isEmptyObject = (obj) => {
    return Object.keys(obj).length === 0;
  };

  const onSubmit = () => {
    if (isEmptyObject(activeDivs)) {
      props.alert.error("Please select atleast one option.");
      return;
    }

    const local_search = localStorage.getItem("search_save");
    const local_search_parsed = JSON.parse(local_search);
    // save to localstorage
    const dataToSave = {
      date: dateFormat(new Date(), "mmmm dd"),
      pathname: "recommendation/criteria",
      service_request: local_search_parsed.service_request,
      message: local_search_parsed.message,
      aiResponse: local_search_parsed.aiResponse,
      criteria: local_search_parsed.criteria,
      urgency: local_search_parsed.urgency,
      availability: activeDivs,
      status: "pending",
      city: null,
      income: null,
    };
    localStorage.setItem("search_save", JSON.stringify(dataToSave));
    props.history.push({
      pathname: `/recommendation/income`,
    });
  };

  function countTrueFields(obj) {
    let count = 0;

    // Iterate over the object properties
    for (let key in obj) {
      // Check if the property value is true
      if (obj.hasOwnProperty(key) && obj[key] === true) {
        count++;
      }
    }

    return count;
  }

  useEffect(() => {
    // if there is atleast one selection done
    if (!isEmptyObject(activeDivs)) {
      let numOfActive = countTrueFields(activeDivs);

      if (numOfActive > 0) {
        setBtnDisabled(false);
      } else {
        setBtnDisabled(true);
      }
    }
  }, [activeDivs]);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      style={{ backgroundColor: "#FFF" }}
    >
      <div>
        <div
          className=""
          ref={topOfContainer}
          style={{ width: 100, height: 10 }}
        ></div>
        <div className="ccSearch__header">
          <a href="https://counselconnect.ca/">
            <img
              className="lawyer-search-navbar-logo"
              src={require("../images/cc-search-logo--blue.svg")}
              alt="counsel-connect-log"
            />
          </a>
        </div>

        <div className="  searchAvail__body">
          <div className="explainCase__content__form">
            <h4 className="explainCase__content__title searchAvail__content__title">
              When is the best time to contact you?
            </h4>

            <span className="explainCase__content__subtitle">
              Choose all of the times that work for you.
            </span>

            <div className="common__buttonContainer">
              <div
                onClick={() => toggleActive("div1")}
                className={
                  activeDivs["div1"]
                    ? "common__selectButton--active common__selectButton searchAvail__selectButton"
                    : "common__selectButton searchAvail__selectButton"
                }
              >
                <img src={require("../images/icon_early_sun.svg")} />
                <div className="searchAvail__selectButton__txtWrap">
                  <span className="common__selectButton__txt">Early</span>
                  <span className="common__selectButton__subtxt">
                    Before 9am
                  </span>{" "}
                </div>
              </div>

              <div
                onClick={() => toggleActive("div2")}
                className={
                  activeDivs["div2"]
                    ? "common__selectButton--active common__selectButton common__selectButton--consecutive searchAvail__selectButton"
                    : "common__selectButton common__selectButton--consecutive searchAvail__selectButton"
                }
              >
                <img src={require("../images/icon_morning_sun.svg")} />
                <div className="searchAvail__selectButton__txtWrap">
                  <span className="common__selectButton__txt">Morning</span>
                  <span className="common__selectButton__subtxt">
                    9am - 12pm
                  </span>
                </div>
              </div>

              <div
                onClick={() => toggleActive("div3")}
                className={
                  activeDivs["div3"]
                    ? "common__selectButton--active common__selectButton common__selectButton--consecutive searchAvail__selectButton"
                    : "common__selectButton common__selectButton--consecutive searchAvail__selectButton"
                }
              >
                <img src={require("../images/icon_noon_sun.svg")} />
                <div className="searchAvail__selectButton__txtWrap">
                  <span className="common__selectButton__txt">Noon</span>
                  <span className="common__selectButton__subtxt">
                    12pm - 2pm
                  </span>
                </div>
              </div>

              <div
                onClick={() => toggleActive("div4")}
                className={
                  activeDivs["div4"]
                    ? "common__selectButton--active common__selectButton common__selectButton--row2 searchAvail__selectButton"
                    : "common__selectButton common__selectButton--row2 searchAvail__selectButton"
                }
              >
                <img src={require("../images/icon_afternoon_sun.svg")} />
                <div className="searchAvail__selectButton__txtWrap">
                  <span className="common__selectButton__txt">Afternoon</span>
                  <span className="common__selectButton__subtxt">
                    2pm - 5pm
                  </span>
                </div>
              </div>

              <div
                onClick={() => toggleActive("div5")}
                className={
                  activeDivs["div5"]
                    ? "common__selectButton--active common__selectButton common__selectButton--consecutive--row2 searchAvail__selectButton"
                    : "common__selectButton common__selectButton--consecutive--row2 searchAvail__selectButton"
                }
              >
                <img src={require("../images/icon_evening_sun.svg")} />
                <div className="searchAvail__selectButton__txtWrap">
                  <span className="common__selectButton__txt">Evening</span>
                  <span className="common__selectButton__subtxt">
                    After 5pm
                  </span>
                </div>
              </div>

              <div
                onClick={() => toggleActive("div6")}
                className={
                  activeDivs["div6"]
                    ? "common__selectButton--active common__selectButton common__selectButton--consecutive--row2 searchAvail__selectButton"
                    : "common__selectButton common__selectButton--consecutive--row2 searchAvail__selectButton"
                }
              >
                <img src={require("../images/icon_weekend_sun.svg")} />
                <div className="searchAvail__selectButton__txtWrap">
                  <span className="common__selectButton__txt">Weekends</span>
                  <span className="common__selectButton__subtxt">
                    Sat - Sun
                  </span>
                </div>
              </div>
            </div>

            <button
              onClick={onSubmit}
              className={`searchCriteria__content__btn ${
                btnDisabled && "disabled"
              }`}
              type="button"
              disabled={btnDisabled}
            >
              <span className="searchCriteria__content__btnTxt"> Continue</span>

              <Icon.ArrowRight color="#FFF" />
            </button>
            {/* 
          {lawyerFields.map((field, index) => (
            <div key={index} className="status-search-lawyer-button-block">
              <button
                data-recording-click-props={`{"category":"${field.value}"}`}
                type="submit"
                className="status-search-lawyer-button"
                onClick={btnClick}
                key={index.id}
                value={field.value}
              >
                {field.value}
                <span style={{ marginLeft: "10px" }}></span>
              </button>
            </div>
          ))} */}
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default withAlert()(SearchAvailability);
