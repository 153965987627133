import React, { useEffect, useState } from "react";

import http from "../../../utils/http";
import environment from "../../../utils/environment";
import Reviews from "./Reviews";

const FirmsReviewsSection = (props) => {
  const { profileId } = props;

  const [reviewsPerPage] = useState(3);
  const [reviewsToShow, setReviewsToShow] = useState([]);
  const [arrayToHoldReviews, setArrayToHoldReviews] = useState([]);

  const [next, setNext] = useState(3);
  const [reviews, setReviews] = useState([]);

  const loopWithSlice = (start, end) => {
    const slicedPosts = reviews.slice(start, end);
    setReviewsToShow([...arrayToHoldReviews, ...slicedPosts]);
  };

  const handleShowMoreReviews = () => {
    loopWithSlice(next, next + reviewsPerPage);
    setNext(next + reviewsPerPage);
  };

  const getFirmReviews = async () => {
    console.log(profileId);
    const response = await http.post(
      `${environment.resolveApi().rest}/law_firm/reviews/`,
      { profileId }
    );
    console.log(response.data);
    setReviews(response.data);

    const slicedPosts = response.data.slice(0, 3);
    setReviewsToShow([...arrayToHoldReviews, ...slicedPosts]);
  };

  useEffect(() => {
    getFirmReviews();
    loopWithSlice(0, reviewsPerPage);

    return () => {
      setArrayToHoldReviews([]);
      setReviewsToShow([]);
      setReviews([]);
    };
  }, [profileId]);

  return (
    <div className="reviews-container">
      <div className="social-profile-reviews-header-wrap">
        <div className="valid-reviews-container">
          <h5 className="social-profile-txt-dark">
            <strong>{reviews.length} Detailed Reviews</strong>
          </h5>

          <small className="social-profile-txt-light">
            All reviews are the subjective opinions of a client and not of
            CounselConnect.
          </small>
        </div>

        <div className="social-profile-line-wrap">
          <img src={require("./images/oval.svg")} alt="oval" />
          <hr />
        </div>
      </div>

      <Reviews reviewsToRender={reviewsToShow} {...props} />

      {reviewsToShow.length !== reviews.length ? (
        <button
          className="load-more-reviews-btn"
          onClick={handleShowMoreReviews}
        >
          See more reviews
        </button>
      ) : (
        <p className="reached-the-end-msg">
          You have reached the end of this practitioners reviews.
        </p>
      )}
    </div>
  );
};
export default FirmsReviewsSection;
