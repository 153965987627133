import React, { useEffect, useState } from "react";
import StarRatings from "react-star-ratings";
import Moment from "react-moment";

const Reviews = ({ reviewsToRender }) => {
  return reviewsToRender.map((rev, index) => (
    <div className="review" key={index}>
      <div className="rating">
        <div className="rating-container">
          <StarRatings
            className="rating-container"
            rating={parseInt(rev.rating)}
            starRatedColor="#3AC18D"
            numberOfStars={5}
            name="rating"
            starDimension="26px"
            starSpacing="2px"
          />
        </div>

        <p className="social-profile-txt-dark" style={{ marginTop: "10px" }}>
          Reviewed
        </p>

        <p className="social-profile-txt-light">
          <Moment fromNow>{rev.date}</Moment>
        </p>
      </div>
      <p className="social-profile-txt-light">{rev.review}</p>
    </div>
  ));
};
export default Reviews;
