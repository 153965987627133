import React, { useEffect } from "react";
import { Switch } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getCurrentProfile } from "./actions/profileActions";

import Navbar from "./components/reusable/navbar/Navbar";
import Dashboard from "./components/sole_prac_portal/profile_dashboard/Dashboard.jsx";
import CreateProfile from "./components/sole_prac_portal/create-profile/CreateProfile";
import EditProfile from "./components/sole_prac_portal/profile_info/EditProfile";
import Credentials from "./components/sole_prac_portal/profile_credentials/Credentials.jsx";
import AddAvailability from "./components/sole_prac_portal/profile_availability/AddAvailability.jsx";
import AccountInfo from "./components/sole_prac_portal/profile_settings/account_info/AccountInfo.jsx";
import Contact from "./components/sole_prac_portal/profile_settings/contact/Contact.jsx";
import PrivateRoute from "./components/reusable/PrivateRoute";
import ClientList from "./components/sole_prac_portal/client-management/ClientList.jsx";
import ClientDetails from "./components/sole_prac_portal/client-management/ClientDetails.jsx";
import NotFound from "./components/reusable/not_found/NotFound";
// import AddEducation from "./components/profile/add_creds/AddEducation";

import "./style/index.scss";
import Subscription from "./components/sole_prac_portal/profile_settings/subscription/Subscription";

const Main = (props) => {
  // runs once on mount
  useEffect(() => {
    props.getCurrentProfile();
  }, [props]);

  return (
    <div className="Main">
      <Navbar />
      <Switch>
        <PrivateRoute exact path="/" component={Dashboard} />
        <PrivateRoute exact path="/create-profile" component={CreateProfile} />
        <PrivateRoute exact path="/edit-profile" component={EditProfile} />
        <PrivateRoute exact path="/add-experience" component={Credentials} />
        <PrivateRoute
          exact
          path="/add-availability"
          component={AddAvailability}
        />
        <PrivateRoute exact path="/account" component={AccountInfo} />
        <PrivateRoute exact path="/contact" component={Contact} />
        <PrivateRoute exact path="/client-list" component={ClientList} />
        <PrivateRoute exact path="/client/:email" component={ClientDetails} />
        <PrivateRoute exact path="/subscription" component={Subscription} />
        <PrivateRoute exact path="*" component={NotFound} />
      </Switch>
    </div>
  );
};

Main.propTypes = {
  getCurrentProfile: PropTypes.func.isRequired,
};

export default connect(null, { getCurrentProfile })(Main);
