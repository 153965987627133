import React, { Component } from "react";
// import { Link } from 'react-router-dom';

import "./Navbar.scss";

class Navbar extends Component {
  render() {
    return (
      <div>
        <nav className="navbar navbar-expand-sm navbar-light   navbar-customize">
          <div className="container">
            <a className="navbar-brand" href="https://counselconnect.ca">
              <img
                className="logo"
                src={require("../../lawyer_search_app/law_search/images/cc-search-logo.svg")}
                alt=""
              />
            </a>

            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#mobile-nav"
            >
              <i className="navbar-toggler-icon" />
            </button>
            {/* <div className="collapse navbar-collapse" id="mobile-nav">
              <ul className="navbar-nav ml-auto">
                <li className="nav-item">
                  <a className="nav-link" href="https://counselconnect.ca/lawyer-home">
                    <h3>For Lawyers</h3>
                  </a>
                </li>
                <li className="nav-item">
                <a className="nav-link" href="https://app.counselconnect.ca/recommendation/reason">
                  <h3>Browse</h3>
                </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="https://counselconnect.ca/about">
                    <h3>About Us</h3>
                  </a>
                </li>
               
              </ul>
            </div> */}
          </div>
        </nav>
      </div>
    );
  }
}

export default Navbar;
