import React, { Component } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import './TextAreaField.css';

class TextAreaField extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: '',
      name: '',
      placeholder: '',
      value: '',
      error: [],
      info: '',
    };
    this.onChange = this.onChange.bind(this);
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }


  modalTextArea() {
    return (
      <div className="modal-text-area-field-container">
        <span className="modal-text-area-field-title">{this.props.title}</span>
        <textarea
          className={classnames(this.props.className, {
            '': this.props.error,
          })}
          placeholder={this.props.placeholder}
          name={this.props.name}
          value={this.props.value}
          onChange={this.props.onChange}
        />
        {this.props.info && <small className="modal-text-area-field-info">{this.props.info}</small>}
        {this.props.error && <div className="modal-input-small-error">{this.props.error}</div>}
      </div>
    );
  }

  render() {

    if (this.props.modalTextArea) {
      return this.modalTextArea();
    }

    return (
      <div className="lw-portal-text-area-field-container">
        <p className="social-profile-txt-light">{this.props.title}</p>
        <textarea
          className={classnames(this.props.className, {
            'text-field-input-error': this.props.error,
          })}
          placeholder={this.props.placeholder}
          name={this.props.name}
          value={this.props.value}
          onChange={this.props.onChange}
        />
        {this.props.info && <small className="lw-portal-text-area-field-info">{this.props.info}</small>}
        {this.props.error && <div className="text-field-input-small-error">{this.props.error}</div>}
      </div>
    );
  }
}

TextAreaField.propTypes = {
  title: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string.isRequired,
  info: PropTypes.string,
  error: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

export default TextAreaField;
