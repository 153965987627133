import React, { useEffect, useState } from "react";

import http from "../../../utils/http";
import environment from "../../../utils/environment";
import Reviews from "./Reviews";

const reviewsPerPage = 3;
let arrayToHoldReviews = [];

const ReviewsSection = (props) => {
  const { profile } = props;

  const [reviewsToShow, setReviewsToShow] = useState([]);
  // const [profileReviews, setProfileReviews] = useState([]);

  const [next, setNext] = useState(3);

  const loopWithSlice = (start, end) => {
    const slicedPosts = profile.reviews.slice(start, end);
    arrayToHoldReviews = [...arrayToHoldReviews, ...slicedPosts];
    setReviewsToShow(arrayToHoldReviews);
  };

  const handleShowMoreReviews = () => {
    loopWithSlice(next, next + reviewsPerPage);
    setNext(next + reviewsPerPage);
  };

  useEffect(() => {
    arrayToHoldReviews = [];
    loopWithSlice(0, reviewsPerPage);
  }, []);

  return (
    <div className="reviews-container">
      <div className="social-profile-reviews-header-wrap">
        <div className="valid-reviews-container">
          <h5 className="social-profile-txt-dark">
            <strong>{profile.reviews.length} Detailed Reviews</strong>
          </h5>

          <small className="social-profile-txt-light">
            All reviews are the subjective opinions of a client and not of
            CounselConnect.
          </small>
        </div>

        <div className="social-profile-line-wrap">
          <img src={require("./images/oval.svg")} alt="oval" />
          <hr />
        </div>
      </div>

      <Reviews reviewsToRender={reviewsToShow} {...props} />

      {reviewsToShow.length !== profile.reviews.length ? (
        <button
          className="load-more-reviews-btn"
          onClick={handleShowMoreReviews}
        >
          See more reviews
        </button>
      ) : (
        <p className="reached-the-end-msg">{`You have reached the end of this practitioners reviews.`}</p>
      )}
    </div>
  );
};
export default ReviewsSection;
